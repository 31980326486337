import React from "react";
import { getSettingValueByKey } from "../../../utils/settingsUtils";

const FaqAreaTemOne = ({webimage, faqs, settings}) => {

  return (
    <>
      <div
        className='faq-area faq-area-margin-top bg-cover pd-top-90 pd-bottom-110'
        style={{ backgroundImage: 'url("./assets/img/bg/3.png")' }}
      >
        <div className='container'>
          <div className='row pd-top-120'>
            <div
              className='col-xl-5 col-lg-6 col-md-8 order-lg-last'
              data-aos='fade-left'
              data-aos-delay='100'
              data-aos-duration='1500'
            >
              <div className='about-thumb-inner pt-lg-3'>
                <img
                  className='main-img'
                  src={webimage.faq_image_url}
                  alt='img'
                />
                <img
                  className='animate-img-bottom-right top_image_bounce'
                  src={webimage.faq_thumb_image_url}
                  alt='img'
                />
              </div>
            </div>
            <div
              className='col-xl-7 col-lg-6'
              data-aos='fade-right'
              data-aos-delay='100'
              data-aos-duration='1500'
            >
              <div className='section-title mb-0 mt-4 mt-lg-0'>
                <h6 className='sub-title'>{getSettingValueByKey(settings,'faq_head')}</h6>
                <h2 className='title'>
                  {getSettingValueByKey(settings,'faq_title')}
                </h2>
                <p className='content'>
                  {getSettingValueByKey(settings,'faq_detail')}
                </p>
              </div>
              <div className='accordion accordion-inner style-2 accordion-icon-left mt-3' id='accordionExample'>
                  {faqs.map((data, index) => (
                    <div className='accordion-item' key={index}>
                      <h2 className='accordion-header' id={`heading${index}`}>
                        <button
                          className='accordion-button'
                          type='button'
                          data-bs-toggle='collapse'
                          data-bs-target={`#collapse${index}`}
                          aria-expanded='true'
                          aria-controls={`collapse${index}`}
                        >
                          {data.name}
                        </button>
                      </h2>
                      <div
                        id={`collapse${index}`}
                        className={`accordion-collapse collapse ${index === 0 ? 'show' : ''}`}
                        aria-labelledby={`heading${index}`}
                        data-bs-parent='#accordionExample'
                      >
                        <div className='accordion-body'>
                          {data.detail}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FaqAreaTemOne;
