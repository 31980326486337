import React, { useEffect, useState } from "react";
import {
  FaCalendarAlt,
  FaCheckCircle,
  FaFacebookF,
  FaRegFolderOpen,
  FaInstagram,
  FaQuoteRight,
  FaTwitter,
  FaRegUser,
  FaChevronRight,
  FaChevronLeft,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import $axios from "../axios";
import ReactHtmlParser from 'html-react-parser';

const BlogDetailsArea = ({blog}) => {

  const [blogCat, setBlogCat] = useState([]);

  useEffect(()=>{

    fetchBlogCat();

  },[]);

  const fetchBlogCat = async () =>{
    const response = await $axios.get('/blog-category/');
    setBlogCat(response.data);

  }
  
  return (
    <>
      {/* ==================== Blog Details Area start ====================*/}
      <div className='blog-area pd-top-120 pd-bottom-120'>
        <div className='container'>
          <div className='row'>
            
            <div className='col-lg-8'>
              <div className='blog-details-page-content'>
                <div className='single-blog-inner'>
                  <div className='thumb'>
                    <img src={blog.image_url} alt='img' />
                  </div>
                  <div className='details'>
                    <ul className='blog-meta'>
                      <li>
                        <FaRegUser /> By Admin
                      </li>
                      <li>
                        <FaRegFolderOpen /> Category
                      </li>
                    </ul>
                    <blockquote>
                      <p>{ReactHtmlParser(`${blog.detail}`)}</p>
                    </blockquote>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-lg-4 col-12'>
              <div className='td-sidebar'>
               
                <div className='widget widget_catagory'>
                  <h4 className='widget-title'>Catagory</h4>
                  <ul className='catagory-items'>
                   {blogCat && blogCat.map((data,index)=>(
                    <li key={index}>
                      <Link to={`/blog-category/${data.id}`}>{data.name} <span>{data.blogs_count}</span></Link>
                    </li>
                   ))}
                  </ul>
                </div>
               
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ==================== Blog Details Area End ====================*/}
    </>
  );
};

export default BlogDetailsArea;
