import React, { useContext, useEffect, useRef, useState } from "react";
import CustomerSideBar from "./partials/CustomerSidebar";
import { useForm } from "react-hook-form";
import $axios from "../../axios";
import { toast, Toaster } from "react-hot-toast";

const Profile = ({customer}) => {

  const [formData, setFormData] = useState(customer);
  const { register, handleSubmit, setError, reset, formState: { errors } } = useForm({
    defaultValues: customer
  });

  const [countries, setCountries] = useState([]);

  console.log(formData,'formData');
  
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
      await getCountries();
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value.trim() });
  };

  const getCountries = async () => {
    const response = await fetch('/json/countries.json');
    const data = await response.json();
    setCountries(data);
  };

  const submitForm = async (formData) => {
    try{
     
      const response = await $axios.post('/customer/profile-update',formData);
      
      if (response.data.success) {
        toast.success(response.data.message);
       
      }else{
        toast.error(response.data.message);
      }
    }catch (error) {
      const errorMessage = error.response?.status === 422 ? 
      Object.values(error.response.data.error).flat()[0] :
      error.response?.data.error || 'Something went wrong';
      console.error('Error reset Submit:', errorMessage);
      toast.error(errorMessage);
      console.error('Error CustomerProfile:', error);
    }
    
  }

  return (
    <>
      <div className="pd-top-120 pd-bottom-120">
          <div className="container">
            <div className="row gy-4">
              <div className="col-lg-3">
                <CustomerSideBar customer={customer}/>
              </div>
              <div className="col-lg-9 ps-lg-4">
                <form onSubmit={handleSubmit(submitForm)}>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="cart-personal__content bg-gray p-lg-4 p-2 rounded ">
                            <h4 className=" mb-4 cart-personal__title mb-32">Profile</h4>
                                <div className="mb-4 single-input-inner">
                                    <label for="name" className="form-label font-16 mb-2 fw-500 font-heading">
                                        Full Name <span className="text-danger">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        className="common-input"
                                        style={errors.name ? { border: "1px solid red" } : {}}
                                        id="name"
                                        placeholder="Name"
                                        name="name"
                                        maxLength={255}
                                        onChange={handleChange}
                                        {...register("name", {
                                        required: "This field is required.",
                                        maxLength: {
                                            value: 255,
                                            message: "This input exceeds max length 100.",
                                        },
                                        })}
                                    />
                                    {errors.name && <small className="text-danger">{errors.name?.message}</small>}
                                </div>

                                <div className="mb-4 single-input-inner">
                                    <label for="email" className="form-label font-16 mb-2 fw-500 font-heading">Email Address <span className="text-danger">*</span> </label>
                                    <input
                                        type="email"
                                        className="common-input"
                                        style={errors.email ? { border: "1px solid red" } : {}}
                                        id="email"
                                        placeholder="Email"
                                        name="email"
                                        maxLength={250}
                                        onChange={handleChange}
                                        {...register("email", {
                                        required: "This field is required.",
                                        maxLength: {
                                            value: 250,
                                            message: "This input exceeds max length 100.",
                                        },
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                            message: "Invalid email address."
                                        }
                                        })}
                                    />
                                    {errors.email && <small className="text-danger">{errors.email?.message}</small>}
                                </div>
                            
                                <div className="mb-4 single-input-inner">
                                    <label for="mobileNumber" className="form-label font-16 mb-2 fw-500 font-heading">Mobile Number <span className="text-danger">*</span> </label>
                                    <input
                                        type="tel"
                                        className="common-input"
                                        style={errors.phone ? { border: "1px solid red" } : {}}
                                        id="phone"
                                        placeholder="Phone"
                                        name="phone"
                                        maxLength={13}
                                        onChange={handleChange}
                                        {...register("phone", {
                                        required: "This field is required.",
                                        maxLength: {
                                            value: 13,
                                            message: "This input exceeds max length 13.",
                                        },
                                        pattern: {
                                            value: /^\d+$/,
                                            message: "This input is number only.",
                                        },
                                        })}
                                    />
                                    {errors.mobile && <small className="text-danger">{errors.mobile?.message}</small>}
                                </div>

                                <div className="mb-4 single-input-inner">
                                    <label for="homeAddress" className="form-label font-16 mb-2 fw-500 font-heading">Address <span className="text-danger">*</span> </label>
                                    <input
                                        type="text"
                                        className="common-input"
                                        style={errors.address ? { border: "1px solid red" } : {}}
                                        id="address"
                                        name="address"
                                        placeholder="eg: house , area"
                                        maxLength={191}
                                        onChange={handleChange}
                                        {...register("address", {
                                        required: "This field is required.",
                                        maxLength: {
                                            value: 191,
                                            message: "This input exceeds max length 100.",
                                        },
                                        })}
                                    />
                                    {errors.address && <small className="text-danger">{errors.address?.message}</small>}
                                </div>

                                <div className="mb-4 single-input-inner">
                                    <label for="state" className="form-label font-16 mb-2 fw-500 font-heading">State/Thana <span className="text-danger">*</span> </label>
                                    <input
                                        type="text"
                                        className="common-input"
                                        style={errors.state ? { border: "1px solid red" } : {}}
                                        id="state"
                                        name="state"
                                        placeholder="State"
                                        maxLength={100}
                                        onChange={handleChange}
                                        {...register("state", {
                                        required: "This field is required.",
                                        maxLength: {
                                            value: 100,
                                            message: "This input exceeds max length 100.",
                                        },
                                        })}
                                    />
                                    {errors.state && <small className="text-danger">{errors.state?.message}</small>}
                                </div>

                                <div className="mb-4 single-input-inner">
                                    <label for="city" className="form-label font-16 mb-2 fw-500 font-heading">City <span className="text-danger">*</span> </label>
                                    <input
                                        type="text"
                                        className="common-input"
                                        style={errors.city ? { border: "1px solid red" } : {}}
                                        id="city"
                                        placeholder="City"
                                        maxLength={100}
                                        name="city"
                                        onChange={handleChange}
                                        {...register("city", {
                                        required: "This field is required.",
                                        maxLength: {
                                            value: 100,
                                            message: "This input exceeds max length 100.",
                                        },
                                        })}
                                    />
                                    {errors.city && <small className="text-danger">{errors.city?.message}</small>}
                                </div>

                                <div className="mb-4 single-input-inner">
                                    <label for="disDivision" className="form-label font-16 mb-2 fw-500 font-heading">District/Division <span className="text-danger">*</span> </label>
                                    <input
                                        type="text"
                                        className="common-input"
                                        style={errors.division ? { border: "1px solid red" } : {}}
                                        id="division"
                                        name="division"
                                        placeholder="eg: house , area"
                                        maxLength={100}
                                        onChange={handleChange}
                                        {...register("division", {
                                        required: "This field is required.",
                                        maxLength: {
                                            value: 100,
                                            message: "This input exceeds max length 100.",
                                        },
                                        })}
                                    />
                                    {errors.division && <small className="text-danger">{errors.division?.message}</small>}
                                </div>

                                <div className="mb-4 single-input-inner">
                                    <label for="country" className="form-label font-16 mb-2 fw-500 font-heading">Country <span className="text-danger">*</span> </label>
                                    <select 
                                        className="form-control" 
                                        style={errors.country ? { border: "1px solid red" } : {}}
                                        name="country" 
                                        id="country" 
                                        onChange={handleChange} 
                                        {...register("country", {
                                            required: "This field is required.",
                                        })}>
                                        <option value="">Select...</option>
                                        {countries.map((data,index)=>(
                                            <option value={data.name} key={index} selected={ (customer.country ?? null) === data.name}>{data.name}</option>
                                        ))}
                                    </select>
                                    {errors.country && <small className="text-danger">{errors.country?.message}</small>}
                                </div>

                                <button type="submit" className="btn btn-primary">
                                    Update Profile
                                </button>

                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="cart-personal__content bg-gray p-lg-4 p-2 rounded ">
                          <h4 className=" mb-4 cart-personal__title mb-32">Change Password</h4>
                            <div className="mb-4 single-input-inner">
                                <label for="name" className="form-label font-16 mb-2 fw-500 font-heading">
                                    Old Password <span className="text-danger">*</span>
                                </label>
                                <input
                                    type="text"
                                    className="common-input"
                                    style={errors.old_password ? { border: "1px solid red" } : {}}
                                    id="old_password"
                                    placeholder="Write Old Password"
                                    name="old_password"
                                    maxLength={255}
                                    onChange={handleChange}
                              
                                />
                                {errors.old_password && <small className="text-danger">{errors.old_password?.message}</small>}
                            </div>

                            <div className="mb-4 single-input-inner">
                                <label for="name" className="form-label font-16 mb-2 fw-500 font-heading">
                                    New Password <span className="text-danger">*</span>
                                </label>
                                <input
                                    type="text"
                                    className="common-input"
                                    style={errors.new_password ? { border: "1px solid red" } : {}}
                                    id="new_password"
                                    placeholder="Write New Password"
                                    name="new_password"
                                    maxLength={255}
                                    onChange={handleChange}
                                 
                                />
                                {errors.new_password && <small className="text-danger">{errors.new_password?.message}</small>}
                            </div>

                            <div className="mb-4 single-input-inner">
                                <label for="name" className="form-label font-16 mb-2 fw-500 font-heading">
                                    Confirm New Password <span className="text-danger">*</span>
                                </label>
                                <input
                                    type="text"
                                    className="common-input"
                                    style={errors.password_confirmation ? { border: "1px solid red" } : {}}
                                    id="password_confirmation"
                                    placeholder="Confirm Password"
                                    name="password_confirmation"
                                    maxLength={255}
                                    onChange={handleChange}
                                    
                                />
                            </div>

                            <button type="submit" className="btn btn-primary">
                                    Update Password
                            </button>
                        

                        </div>
                      </div>

                    </div>
                </form>
              </div>
            </div>
          </div>
        </div>
    </>
  );
};

export default Profile;
