
import React, { useContext, useEffect, useRef, useState }from "react";
import $axios from "../../../axios";
import { Link } from "react-router-dom";


const CustomerCardBox = () => {

    const [order, setOrder] = useState([]);

    useEffect(()=>{
        fetchData();
    },[]);

    const fetchData = async () => {
        try {
          const response = await $axios.get('/customer/dashboard');
          setOrder(response.data);
        } catch (error) {
          console.error("Error fetching :", error);
        }
      };

  return (
    <>
      <div class="row gy-4">
            <div class="col-xl-6 col-sm-6">
            <div class="card-1">
                <h3>{order.pending_order}</h3>
                <p className="fs-14px">Total Pending Order</p>
                <div class="view-all">
                <Link to="/customer-orders" className="fs-14px">View Details 
                  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 16 16"><path fill="currentColor" fill-rule="evenodd" d="M10.159 10.72a.75.75 0 1 0 1.06 1.06l3.25-3.25L15 8l-.53-.53l-3.25-3.25a.75.75 0 0 0-1.061 1.06l1.97 1.97H1.75a.75.75 0 1 0 0 1.5h10.379z" clip-rule="evenodd"/></svg>
                </Link>
                </div>
            </div>
            </div>
            <div class="col-xl-6 col-sm-6">
            <div class="card-1">
                <h3>{order.in_process_order}</h3>
                <p className="fs-14px">Total Progress Order</p>
                <div class="view-all">
                <Link to="/customer-orders" className="fs-14px">View Details
                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 16 16"><path fill="currentColor" fill-rule="evenodd" d="M10.159 10.72a.75.75 0 1 0 1.06 1.06l3.25-3.25L15 8l-.53-.53l-3.25-3.25a.75.75 0 0 0-1.061 1.06l1.97 1.97H1.75a.75.75 0 1 0 0 1.5h10.379z" clip-rule="evenodd"/></svg>
                </Link>
                </div>
            </div>
            </div>
            <div class="col-xl-6 col-sm-6">
            <div class="card-1">
                <h3>{order.incomplete_order}</h3>
                <p className="fs-14px">Total Incomplete Order</p>
                <div class="view-all">
                <Link to="/customer-orders" className="fs-14px">View Details 
                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 16 16"><path fill="currentColor" fill-rule="evenodd" d="M10.159 10.72a.75.75 0 1 0 1.06 1.06l3.25-3.25L15 8l-.53-.53l-3.25-3.25a.75.75 0 0 0-1.061 1.06l1.97 1.97H1.75a.75.75 0 1 0 0 1.5h10.379z" clip-rule="evenodd"/></svg>
                </Link>
                </div>
            </div>
            </div>
            <div class="col-xl-6 col-sm-6">
            <div class="card-1">
                <h3>{order.complete_order}</h3>
                <p className="fs-14px">Total Complete Order</p>
                <div class="view-all">
                <Link to="/customer-orders" className="fs-14px">View Details 
                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 16 16"><path fill="currentColor" fill-rule="evenodd" d="M10.159 10.72a.75.75 0 1 0 1.06 1.06l3.25-3.25L15 8l-.53-.53l-3.25-3.25a.75.75 0 0 0-1.061 1.06l1.97 1.97H1.75a.75.75 0 1 0 0 1.5h10.379z" clip-rule="evenodd"/></svg>
                </Link>
                </div>
            </div>
            </div>
            <div class="col-xl-6 col-sm-6">
            <div class="card-1">
                <h3>{order.decline_order}</h3>
                <p className="fs-14px">Total Decline Order</p>
                <div class="view-all">
                <Link to="/customer-orders" className="fs-14px">View Details 
                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 16 16"><path fill="currentColor" fill-rule="evenodd" d="M10.159 10.72a.75.75 0 1 0 1.06 1.06l3.25-3.25L15 8l-.53-.53l-3.25-3.25a.75.75 0 0 0-1.061 1.06l1.97 1.97H1.75a.75.75 0 1 0 0 1.5h10.379z" clip-rule="evenodd"/></svg>
                </Link>
                </div>
            </div>
            </div>
        </div>
    </>
  );
};

export default CustomerCardBox;
