import $axios from "../axios";

export const fetchGallery = async () => {
  try {
    const response = await $axios.get('/gallery');
    return response.data;
  } catch (error) {
    console.error('Error fetching Gallery:', error);
    return [];
  }
};