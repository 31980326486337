import React, { useContext } from "react";
import { GeneralSettingsContext } from "../contexts/GeneralSettingsContext";
import { getSettingValueByKey } from "../utils/settingsUtils";
import ReactHtmlParser from 'html-react-parser';
import AboutAreaTemOne from "./templates/one/AboutAreaTemOne";
import AboutAreaTwo from "./templates/two/AboutAreaTwo";
import AboutAreaThree from "./templates/three/AboutAreaThree";

const AboutAreaOne = ({webimage}) => {

  const setting = useContext(GeneralSettingsContext);

  const renderTemplate = () => {
    switch (getSettingValueByKey(setting,'template_status')) {
      case '1':
        return <AboutAreaTemOne webimage={webimage}  />;
      case '2':
        return <AboutAreaTwo webimage={webimage}  />;
      case '3':
          return <AboutAreaThree webimage={webimage}  />;
      default:
        return <AboutAreaTemOne webimage={webimage} />;
    }
  };

  if(!setting){
    return null;
  }

  return (
    <>
      {renderTemplate()}
    </>
  );
};

export default AboutAreaOne;
