import React, { useContext, useEffect, useState } from "react";
import { GeneralSettingsContext } from "../contexts/GeneralSettingsContext";
import { getSettingValueByKey } from "../utils/settingsUtils";
import { fetchFaq } from "../scripts/faqList";
import FaqAreaTemOne from "./templates/one/FaqAreaTemOne";
import FaqAreaTwo from "./templates/two/FaqAreaTwo";
import FaqAreaThree from "./templates/three/FaqAreaThree";

const FaqAreaOne = ({webimage}) => {

  const settings = useContext(GeneralSettingsContext);
  const [faqs, setFaqs] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchFaq();
      setFaqs(data);
     
    };

    fetchData();
  }, []);

  const renderTemplate = () => {
    switch (getSettingValueByKey(settings,'template_status')) {
      case '1':
        return <FaqAreaTemOne webimage={webimage} faqs={faqs} settings={settings} />;
      case '2':
        return <FaqAreaTwo webimage={webimage} faqs={faqs} settings={settings} />;
      case '3':
          return <FaqAreaThree webimage={webimage} faqs={faqs} settings={settings} />;
      default:
        return <FaqAreaTemOne webimage={webimage} faqs={faqs} settings={settings}/>;
    }
  };

 if (!settings) {
    return null;
  }

  return (
    <>
       {renderTemplate()}
    </>
  );
};

export default FaqAreaOne;
