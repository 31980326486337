import React, { useState } from 'react';
import { FaArrowRight, FaSearch } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { getSettingValueByKey } from '../../../utils/settingsUtils';

const NavbarThree = ({ webimage,menu,appUrl,settings }) => {
  const [active, setActive] = useState(false);
  const [searchShow, setSearchShow] = useState(false);
  const menuActive = () => {
    setActive(!active);
  };
  const searchActive = () => {
    setSearchShow(!searchShow);
  };

  // Control sidebar navigation
  let items = document.querySelectorAll('.menu-item-has-children > a');
  for (let i in items) {
    if (items.hasOwnProperty(i)) {
      items[i].onclick = function () {
        this.parentElement
          .querySelector('.sub-menu')
          .classList.toggle('active');
        this.classList.toggle('open');
      };
    }
  }
  return (
    <>
      {/* search popup start*/}
      <div
        className={searchShow ? 'td-search-popup active' : 'td-search-popup '}
        id="td-search-popup"
      >
        <form action="/" className="search-form">
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="Search....."
            />
          </div>
          <button type="submit" className="submit-btn">
            <FaSearch />
          </button>
        </form>
      </div>
      {/* search popup end*/}
      <div
        onClick={searchActive}
        className={searchShow ? 'body-overlay active' : 'body-overlay'}
        id="body-overlay"
      ></div>
      {/* navbar start */}
      <nav className="navbar navbar-area navbar-area_8 navbar-area-3 mt-0 navbar-expand-lg">
        <div className="container nav-container">
          <div className="responsive-mobile-menu">
            <button
              onClick={menuActive}
              className={
                active
                  ? 'menu toggle-btn d-block d-lg-none open'
                  : 'menu toggle-btn d-block d-lg-none'
              }
              data-target="#itech_main_menu"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="icon-left" />
              <span className="icon-right" />
            </button>
          </div>
          <div className="logo">
            <Link className="d-none d-lg-inline-block" to="/">
              <img src={`${appUrl}/assets/img/home-9/1.png`} alt="img" />
            </Link>
            <Link className="d-lg-none d-inline-block" to="/">
            <img src={webimage.header_logo_image_url} alt='img' />
            </Link>
          </div>
          <div className="nav-right-part nav-right-part-mobile">
            <span className="search-bar-btn" onClick={searchActive}>
              <FaSearch />
            </span>
          </div>
          <div
            className={
              active
                ? 'collapse navbar-collapse sopen'
                : 'collapse navbar-collapse'
            }
            id="itech_main_menu"
          >
            <ul className="navbar-nav menu-open text-lg-center ps-lg-5">
            {menu.map((data, index) => (
                <li key={index} className={data?.sub_menus.length > 0 ? 'menu-item-has-children' : ''}>
                  {data.sub_menus.length > 0 ? (
                    <Link to={`/menu/${data.id}/${data.slug}`}>{data?.name}</Link>
                  ) : (
                    <Link to={`/menu/${data.id}/${data.slug}`}>{data?.name}</Link>
                  )}
                  {data.sub_menus.length > 0 && (
                    <ul key={data.id} className='sub-menu'>
                      {data.sub_menus.map((subMenu, key) => (
                        <li key={key}>
                          <Link to={`/sub-menu/${subMenu.id}/${subMenu.slug}`}>{subMenu.name}</Link>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}

              <li>
                <Link to='/products'>Products</Link>
              </li>
              <li>
                <Link to='/contact'>Contact</Link>
              </li>
            </ul>
          </div>
          <div className="nav-right-part nav-right-part-desktop align-self-center">
            <Link className="btn btn-base bg-base-9" to="/register">
              Sign Up <FaArrowRight className="mt-4" />
            </Link>
          </div>
        </div>
      </nav>
      {/* navbar end */}
    </>
  );
};

export default NavbarThree;
