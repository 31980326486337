import React, { useContext } from "react";
import { FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";
import { GeneralSettingsContext } from "../contexts/GeneralSettingsContext";
import { getSettingValueByKey } from "../utils/settingsUtils";
import BannerTemOne from "./templates/one/BannerTemOne";
import BannerTwo from "./templates/two/BannerTwo";
import BannerThree from "./templates/three/BannerThree";

const BannerOne = ({webimage}) => {

  const settings = useContext(GeneralSettingsContext);

  const renderTemplate = () => {
    switch (getSettingValueByKey(settings,'template_status')) {
      case '1':
        return <BannerTemOne webimage={webimage} />;
      case '2':
        return <BannerTwo webimage={webimage} />;
      case '3':
          return <BannerThree webimage={webimage} />;
      default:
        return <BannerTemOne webimage={webimage}/>;
    }
  };

  if (!settings) {
    return null;
  }

  return (
    <>
     {renderTemplate()}
    </>
  );
};

export default BannerOne;
