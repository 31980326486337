import React, { useEffect, useState } from "react";
import Breadcrumb from "../components/Breadcrumb";
import CaseStudyArea from "../components/CaseStudyArea";
import FooterOne from "../components/FooterOne";
import NavBar from "../components/NavBar";
import { useParams } from "react-router-dom";
import $axios from "../axios";
import Layout from "../components/layout/FrontLayout";
import ReactHtmlParser from 'html-react-parser';

const SubMenuDetails = () => {

  const {subMenuId} = useParams();
  const [detail, setDetails] = useState({});

  useEffect(()=>{
    fetchData();
  },[subMenuId]);

  const fetchData = async () =>{
      const response = await $axios.get('/sub-menu/'+subMenuId);
      setDetails(response.data);
  }

  return (
    <>
    <Layout>
        {/* Navigation Bar */}
      <Breadcrumb title={detail.name} />

      {/* Case Study Area */}

      <div className='service-area pd-top-120 pd-bottom-90'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='blog-details-page-content'>
                <div className='single-blog-inner mb-0'>
                  {detail.image != null && (
                    <div className='thumb'>
                    <img src={detail.image_url} alt='img' />
                  </div>
                  )}
                  <div className='details'>
                    {ReactHtmlParser(`${detail.detail}`)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
    </>
  );
};

export default SubMenuDetails;
