import React, { useContext } from "react";
import ReactHtmlParser from 'html-react-parser';
import { GeneralSettingsContext } from "../../../contexts/GeneralSettingsContext";
import { getSettingValueByKey } from "../../../utils/settingsUtils";

const AboutAreaTwo = ({webimage}) => {

  const setting = useContext(GeneralSettingsContext);

  if(!setting){
    return null;
  }

  return (
    <>
    {/* =============== About Area Five End ===============*/}
    <div className="about-area pd-top-60 pd-bottom-100">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 align-self-center">
            <div
              data-aos="fade-right"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <img
                className="main-img m-md-4"
                src={webimage.about_image_url}
                alt="img"
              />
            </div>
          </div>
          <div
            className="col-lg-6 align-self-center   "
            data-aos="fade-left"
            data-aos-delay="100"
            data-aos-duration="1500"
          >
            <div className="section-title mt-5 mt-lg-0 mb-0 ps-lg-4">
              <h6 className="bg-none color-base mb-3">{getSettingValueByKey(setting,'about_head')}</h6>
              <h2 className="title">
              {getSettingValueByKey(setting,'about_title')}
              </h2>
              <p className="content mb-4">
              {ReactHtmlParser(`${getSettingValueByKey(setting,'about_detail')}`)}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    {/* =============== About Area Five End ===============*/}
  </>
  );
};

export default AboutAreaTwo;
