import React, { useState } from 'react';
import {
  FaEnvelope,
  FaFacebookF,
  FaLinkedinIn,
  FaMapMarkerAlt,
  FaPhoneAlt,
  FaPinterestP,
  FaSearch,
  FaTwitter,
} from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { getSettingValueByKey } from '../../../utils/settingsUtils';

const NavbarTwo = ({ webimage,menu,appUrl,settings }) => {
  const [active, setActive] = useState(false);
  const [searchShow, setSearchShow] = useState(false);
  const menuActive = () => {
    setActive(!active);
  };
  const searchActive = () => {
    setSearchShow(!searchShow);
  };

  // Control sidebar navigation
  let items = document.querySelectorAll('.menu-item-has-children > a');
  for (let i in items) {
    if (items.hasOwnProperty(i)) {
      items[i].onclick = function () {
        this.parentElement
          .querySelector('.sub-menu')
          .classList.toggle('active');
        this.classList.toggle('open');
      };
    }
  }
  return (
    <>
      {/* search popup start*/}
      <div
        className={searchShow ? 'td-search-popup active' : 'td-search-popup '}
        id="td-search-popup"
      >
        <form action="/" className="search-form">
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="Search....."
            />
          </div>
          <button type="submit" className="submit-btn">
            <FaSearch />
          </button>
        </form>
      </div>
      {/* search popup end*/}
      <div
        onClick={searchActive}
        className={searchShow ? 'body-overlay active' : 'body-overlay'}
        id="body-overlay"
      ></div>
      {/* ==================== Navbar Two Start ====================*/}
      <div className="navbar-area-wrap navbar-area-4 navbar-area_5">
        <img className="left-bg" src={`${appUrl}/assets/img/bg/17.png`} alt="img" />
        <div className="row">
          <div className=" col-lg-2 col-xl-3 align-self-center">
            <div className="logo">
                <Link to='/'>
                <img src={webimage.header_logo_image_url} alt='img' />
                </Link>
            </div>
          </div>
          <div className="col-lg-10 col-xl-9">
            <div className="navbar-top pe-3">
              <div className="row">
                <div className="col-lg-8">
                  <ul>
                    <li>
                      <FaPhoneAlt /> {getSettingValueByKey(settings,'contact_number')}
                    </li>
                    <li>
                      <FaEnvelope /> {getSettingValueByKey(settings,'contact_email')}
                    </li>
                    <li>
                      <FaMapMarkerAlt /> {getSettingValueByKey(settings,'contact_address')}
                    </li>
                  </ul>
                </div>
                <div className="col-lg-4 text-lg-end">
                  <ul className="topbar-right">
                    <li>Follow On: </li>
                    <li>
                      <Link to="#">
                        <FaFacebookF />
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <FaTwitter />
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <FaLinkedinIn />
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <FaPinterestP />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* navbar start */}
            <nav className="navbar navbar-area navbar-area_5 navbar-area-2 navbar-expand-lg bg-white">
              <div className="container nav-container custom-container ps-lg-0">
                <div className="responsive-mobile-menu">
                  <button
                    onClick={menuActive}
                    className={
                      active
                        ? 'menu toggle-btn d-block d-lg-none open'
                        : 'menu toggle-btn d-block d-lg-none'
                    }
                    data-target="#itech_main_menu"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="icon-left" />
                    <span className="icon-right" />
                  </button>
                </div>
                <div className="nav-right-part nav-right-part-mobile">
                  <Link className="search-bar-btn" to="#">
                    <FaSearch />
                  </Link>
                </div>
                <div
                  className={
                    active
                      ? 'collapse navbar-collapse sopen'
                      : 'collapse navbar-collapse'
                  }
                  id="itech_main_menu"
                >
                  <ul className="navbar-nav menu-open">
                  
                    {menu.map((data, index) => (
                        <li key={index} className={data?.sub_menus.length > 0 ? 'menu-item-has-children' : ''}>
                        {data.sub_menus.length > 0 ? (
                            <Link to={`/menu/${data.id}/${data.slug}`}>{data?.name}</Link>
                        ) : (
                            <Link to={`/menu/${data.id}/${data.slug}`}>{data?.name}</Link>
                        )}
                        {data.sub_menus.length > 0 && (
                            <ul key={data.id} className='sub-menu'>
                            {data.sub_menus.map((subMenu, key) => (
                                <li key={key}>
                                <Link to={`/sub-menu/${subMenu.id}/${subMenu.slug}`}>{subMenu.name}</Link>
                                </li>
                            ))}
                            </ul>
                        )}
                        </li>
                    ))}
                    <li>
                        <Link to='/products'>Products</Link>
                    </li>
                    <li>
                        <Link to='/contact'>Contact</Link>
                    </li>
                  </ul>
                </div>
                <div className="nav-right-part nav-right-part-desktop d-lg-inline-flex align-item-center">
                  <Link className="btn btn-base" to="/register">
                    Sign Up
                  </Link>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>

      {/* ==================== Navbar Two end ====================*/}
    </>
  );
};

export default NavbarTwo;
