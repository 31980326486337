import axios from 'axios';

const user = JSON.parse(localStorage.getItem('user'));
const token = user ? user.token : '';

const $axios = user ? axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	withCredentials: true,
	headers: {
		"Content-Type": "application/json",
		"Accept": "application/json",
		"Authorization" : `Bearer ${token}`
	},
}) : axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	withCredentials: true,
	headers: {
		"Content-Type": "application/json",
		"Accept": "application/json",
	},
});

export default $axios;