import React, { Children, cloneElement, useEffect, useState } from "react";
import NavBar from "../NavBar";
import FooterOne from "../FooterOne";
import $axios from "../../axios";
import { getSettingValueByKey } from "../../utils/settingsUtils";

const Layout = ({ children }) => {

  const [webimage, setwebImage] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(()=>{ fetchWebImages() },[]); 

  const fetchWebImages = async () =>{
      try{
        const result = await $axios.get('/get-image');
        setwebImage(result?.data);
      } catch (error) {
        console.error('Error fetching web images:', error);
      } finally {
        setLoading(false);
      }
  }

  const childrenWithProps = Children.map(children, child =>
    cloneElement(child, { webimage })
  );


    return (
      <>
      
        {loading ? (
           <div className="preloader" id="preloader">
              <div className="preloader-inner">
                  <div className="spinner">
                      <div className="dot1"></div>
                      <div className="dot2"></div>
                  </div>
              </div>
          </div>
        ) : (
          <>
          <NavBar webimage={webimage}/>
            {childrenWithProps}
          <FooterOne webimage={webimage}/>
          </>
        )}
       
      </>
    );
  };
  
  export default Layout;