import React, { useContext, useEffect, useState } from "react";
import { FaSearch } from "react-icons/fa";
import { Link } from "react-router-dom";
import $axios from "../axios";
// import { useQuery } from "react-query";
import { GeneralSettingsContext } from "../contexts/GeneralSettingsContext";
import { getSettingValueByKey } from "../utils/settingsUtils";
import NavBarOne from "./templates/one/NavOne";
import NavbarTwo from "./templates/two/NavTwo";
import NavbarThree from "./templates/three/NavThree";

const NavBar = ({webimage}) => {
  const [active, setActive] = useState(false);
  const [searchShow, setSearchShow] = useState(false);

  const settings = useContext(GeneralSettingsContext);

  const appUrl = process.env.REACT_APP_URL;

  const [menu, setMenu] = useState([]);
  
  useEffect(()=>{ fetchMenu() },[]); 
  const fetchMenu = async () =>{
      const result = await $axios.get('/menu');
      setMenu(result?.data);
  }

  const menuActive = () => {
    setActive(!active);
  };

  const searchActive = () => {
    setSearchShow(!searchShow);
  };

  // Control sidebar navigation
  let items = document.querySelectorAll(".menu-item-has-children > a");
  for (let i in items) {
    if (items.hasOwnProperty(i)) {
      items[i].onclick = function () {
        this.parentElement
          .querySelector(".sub-menu")
          .classList.toggle("active");
        this.classList.toggle("open");
      };
    }
  }

  const renderTemplate = () => {
    switch (getSettingValueByKey(settings,'template_status')) {
      case '1':
        return <NavBarOne webimage={webimage} menu={menu} appUrl={appUrl} settings={settings} />;
      case '2':
        return <NavbarTwo webimage={webimage} menu={menu} appUrl={appUrl} settings={settings} />;
      case '3':
          return <NavbarThree webimage={webimage} menu={menu} appUrl={appUrl} settings={settings} />;
      default:
        return <NavBarOne webimage={webimage} menu={menu} appUrl={appUrl} settings={settings}/>;
    }
  };


  if (!settings) {
    return null;
  }


  return (
    <>
       {renderTemplate()}
    </>
  );
};

export default NavBar;
