import React from "react";
import { FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";
import { getSettingValueByKey } from "../../../utils/settingsUtils";

const ServiceAreaTwo = ({services, settings}) => {
  if (!settings) {
    return null;
  }
  return (
    <>
      <div
        className="service-area bg-cover pd-top-60 pd-bottom-90"
        style={{ backgroundImage: 'url("./assets/img/bg/3.png")' }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-5 col-lg-8">
              <div className="section-title text-center">
                <h6 className="bg-none color-base mb-3">{getSettingValueByKey(settings,'service_head')}</h6>
                <h2 className="title">
                  {getSettingValueByKey(settings,'service_title')}
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
          {services.map((data, index) => (
            <div className="col-lg-4 col-md-6" key={index}>
              <div className="single-service-inner style-3">
                <div className="thumb-img text-end">
                <img src={data.image_url} alt={data.name} />
                </div>
                <div className="details">
                  <h5>
                    <Link to={`/service/${data.id}`}>{data.name}</Link>
                  </h5>
                  <p className="mb-0">
                    {data.short_detail}
                  </p>
                </div>
              </div>
            </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceAreaTwo;
