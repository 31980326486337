import React, { useEffect, useState } from "react";
import Breadcrumb from "../../components/Breadcrumb";
import FooterOne from "../../components/FooterOne";
import NavBar from "../../components/NavBar";
import ServiceDetailsArea from "../../components/ServiceDetailsArea";
import Layout from "../../components/layout/FrontLayout";
import { useParams } from "react-router-dom";
import $axios from "../../axios";

const ServiceDetails = () => {

  const {id} = useParams();
  const [detail, setDetails] = useState({});

  useEffect(()=>{
    fetchData();
  },[id]);

  const fetchData = async () =>{
      const response = await $axios.get('/service/'+id);
      setDetails(response.data);
  }

  return (
    <>
      <Layout>
        {/* Navigation Bar */}
        <Breadcrumb title={detail.name} />

        {/* Service Details Area */}
        <ServiceDetailsArea service={detail} />
      </Layout>
    </>
  );
};

export default ServiceDetails;
