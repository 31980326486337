import React from "react";
import Breadcrumb from "../../components/Breadcrumb";
import Layout from "../../components/layout/FrontLayout";
import Register from "../../components/customer/Register";

const RegisterMain = () => {
  
  return (
    <>
     <Layout>
        {/* Navigation Bar */}
        <Breadcrumb title={"Sign Up"} />

        <Register />
     </Layout>
    </>
  );
};

export default RegisterMain;
