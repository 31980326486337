import React, { useContext, useEffect, useState } from "react";
import Breadcrumb from "../components/Breadcrumb";
import CaseStudyArea from "../components/CaseStudyArea";
import FooterOne from "../components/FooterOne";
import NavBar from "../components/NavBar";
import { Link, useNavigate, useParams } from "react-router-dom";
import $axios from "../axios";
import Layout from "../components/layout/FrontLayout";
import ReactHtmlParser from 'html-react-parser';
import { FaMinus, FaPlus, FaStar } from "react-icons/fa";
import { GeneralSettingsContext } from "../contexts/GeneralSettingsContext";
import { getSettingValueByKey } from "../utils/settingsUtils";
import ShoppingCart from "./ShoppingCart";

const ProductDetails = ({webimage}) => {

  const navigate = useNavigate();
  const {slug} = useParams();
  const [detail, setDetails] = useState({});
  const settings = useContext(GeneralSettingsContext);
  const [valueQty, setValueQty] = useState(1);

  useEffect(()=>{
    fetchData();
  },[slug]);

  const fetchData = async () =>{
      const response = await $axios.get('/product/'+slug);
      setDetails(response.data);
  }
  
  const addToCart = async () =>{
    localStorage.removeItem('cartItems');
    let existingCartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
    const existingItemIndex = existingCartItems.findIndex(item => item.product_id === detail.id);

    if (existingItemIndex !== -1) {
        existingCartItems[existingItemIndex].qty = valueQty;
    } else {
        existingCartItems.push({ 'product_id': detail.id, 'qty': valueQty, 'item' : detail });
    }
    localStorage.setItem('cartItems', JSON.stringify(existingCartItems));

    navigate('/shopping');
  }

  const handleChange = (e) => {
    const inputValue = e.target.value;
    // /^[1-9]\d*$/.test(inputValue) - only positive number input validation
    if (inputValue.length <= 4 && /^\d*$/.test(inputValue)) {
      setValueQty(inputValue);
    }
  };
  
  const handleDecrease = () => {
    if (valueQty > 1) {
      setValueQty(valueQty - 1);
    }
  };

  const handleIncrease = () => {
    var qty = parseInt(valueQty)+1;
    setValueQty(qty);
  };

  if (!settings) {
    return null;
  }

  return (
    <>
      <Layout>

        {/* Navigation Bar */}
        <Breadcrumb title={detail.name} webimage={webimage} />

        <section className="product-details pd-top-120 pd-bottom-120">
            <div className="container">
                <div className="row gx-80">
                    <div className="col-lg-6">
                        <div className="product-thumb">
                            <div className="img">
                              <img src={detail.thumb_image_url} alt={detail.name} />
                            </div>
                            <div className="product-tag">Sale</div>
                        </div>
                    </div>
                    <div className="col-lg-6 align-self-center">
                        <div className="product-about">
                            <p className="price">{getSettingValueByKey(settings,'currency')} {detail.price} {detail.discount_amount > 0 && <del>{getSettingValueByKey(settings,'currency')} {detail.discount_amount}</del>}</p>
                            <h2 className="product-title">{detail.name}</h2> 
                            <div className="product-rating">
                                <span className="star-rating">
                                {[...Array(detail.review_start)].map((_, i) => (
                                  <FaStar key={i} />
                                ))}
                                </span>
                                ({detail.review_start} Reviews)
                            </div>
                            <div className="product_meta m-0">
                                <span className="sku_wrapper">SKU: <span className="sku">{detail.sku}</span></span>
                                <span className="posted_in">Category: <Link to={`/product-category/${detail.product_category?.id}`} rel="tag">{detail.product_category?.name}</Link></span>
                            </div>
                            
                            <div className="product_meta mt-2">
                              <span className="sku_wrapper">Quantity: 
                                <span className="sku">
                                  <div className="cart-item__count">
                                    <button onClick={handleDecrease}><FaMinus/></button>
                                    <input type="number" min="1" value={valueQty} onChange={handleChange} />
                                    <button onClick={handleIncrease}><FaPlus/></button>
                                  </div>
                                </span>
                              </span>
                            </div>

                            {/* <Link className="btn btn-base mt-4"  to="/shopping">Buy Now</Link> */}
                            <button className="btn btn-base mt-4" onClick={addToCart}>Buy Now</button>
                        </div>
                    </div>
                </div>
                <div className="product-tab-area">
                    <ul className="nav product-tab-style1" id="productTab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <a className="nav-link active" id="description-tab" data-bs-toggle="tab" href="#description" role="tab" aria-controls="description" aria-selected="false">Description</a>
                        </li>
                        <li className="nav-item" role="presentation">
                            <a className="nav-link" id="info-tab" data-bs-toggle="tab" href="#add_info" role="tab" aria-controls="add_info" aria-selected="false">Additional Information</a>
                        </li>
                    </ul>
                    <div className="tab-content" id="productTabContent">
                        <div className="tab-pane fade show active" id="description" role="tabpanel" aria-labelledby="description-tab">
                          {detail.description && ReactHtmlParser(`${detail.description}`)}
                        </div>
                        <div className="tab-pane fade" id="add_info" role="tabpanel" aria-labelledby="add_info">
                          {detail.information && ReactHtmlParser(`${detail.information}`)}
                        </div>
                    </div>
                </div>
            </div>
        </section>

      </Layout>
    </>
  );
};

export default ProductDetails;
