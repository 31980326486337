import React, { useContext, useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";
import { fetchServices } from "../scripts/serviceList";
import { getSettingValueByKey } from "../utils/settingsUtils";
import { GeneralSettingsContext } from "../contexts/GeneralSettingsContext";
import ServiceAreaTemOne from "./templates/one/ServiceAreaTemOne";
import ServiceAreaTwo from "./templates/two/ServiceAreaTwo";
import ServiceAreaThree from "./templates/three/ServiceAreaThree";

const ServiceAreaOne = () => {
  const settings = useContext(GeneralSettingsContext);
 
  const [services, setServices] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      if(!services?.length){
        const data = await fetchServices();
        setServices(data);
      }
    };

    fetchData();
  }, []);

  const renderTemplate = () => {
    switch (getSettingValueByKey(settings,'template_status')) {
      case '1':
        return <ServiceAreaTemOne services={services} settings={settings} />;
      case '2':
        return <ServiceAreaTwo services={services} settings={settings} />;
      case '3':
          return <ServiceAreaThree services={services} settings={settings} />;
      default:
        return <ServiceAreaTemOne services={services} settings={settings}/>;
    }
  };

  if (!settings) {
    return null;
  }
  return (
    <>
      {renderTemplate()}
    </>
  );
};

export default ServiceAreaOne;
