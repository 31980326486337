import React from "react";
import Breadcrumb from "../components/Breadcrumb";
import Layout from "../components/layout/FrontLayout";
import { useAuth } from "../contexts/CustomerAuthContext";
import CustomerSideBar from "../components/customer/partials/CustomerSidebar";
import CustomerOrderList from "../components/customer/CustomerOrderList";

const UserOrder = () => {

  const { user , setUser } = useAuth();
  
  return (
    <>
     <Layout>
        {/* Navigation Bar */}
        <Breadcrumb title={"My Orders"} />
        <div className="pd-top-120 pd-bottom-120">
          <div className="container">
            <div className="row gy-4">
              <div className="col-lg-3">
                <CustomerSideBar customer={user.user}/>
              </div>
              <div className="col-lg-9 ps-lg-4">
                  <CustomerOrderList />
              </div>
            </div>
          </div>
        </div>
     </Layout>
    </>
  );
};

export default UserOrder;
