import React from "react";
import { FaFacebookF, FaInstagram, FaTwitter, FaYoutube } from "react-icons/fa";
import { Link } from "react-router-dom";
import { getSettingValueByKey } from "../../../utils/settingsUtils";

const TeamAreaThree = ({settings, teams}) => {

    return (
        <>
          {/* team-area start */}
          <div className="team-area bg-black-2-ico pd-top-115 pd-bottom-90">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-6">
                  <div className="section-title text-center">
                    <h6 className="sub-title line-double text-white">{getSettingValueByKey(settings,'team_head')}</h6>
                    <h2 className="title text-white">
                        {getSettingValueByKey(settings,'team_title')}
                    </h2>
                  </div>
                </div>
              </div>
              <div className="row">
                
              {teams.map((data,index) => (
                <div className="col-lg-4 col-md-6">
                  <div className="single-creator-inner style-2 text-center">
                    <div className="thumb">
                        <img src={data.image_url} alt='img' />
                    </div>
                    <div className="details">
                      <ul className="social-media">
                        <li>
                          <a href={data.fb_link}>
                            <FaFacebookF />
                          </a>
                        </li>
                        <li>
                          <a href={data.twitter_link}>
                            <FaTwitter />
                          </a>
                        </li>
                        <li>
                          <a href={data.insta_link}>
                            <FaInstagram />
                          </a>
                        </li>
                      </ul>
                      <h5 className="text-white">{data.name}</h5>
                      <p className="mb-0 text-white">{data.designation}</p>
                    </div>
                  </div>
                </div>
                ))}
              </div>
            </div>
          </div>
          {/* team-area start */}
        </>
      );
};

export default TeamAreaThree;
