import React from "react";
import Breadcrumb from "../../components/Breadcrumb";
import Layout from "../../components/layout/FrontLayout";
import Login from "../../components/customer/Login";

const LoginMain = () => {
  
  return (
    <>
     <Layout>
        {/* Navigation Bar */}
        <Breadcrumb title={"Sign In"} />

        <Login />
     </Layout>
    </>
  );
};

export default LoginMain;
