import React, { useContext, useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { toast, Toaster } from "react-hot-toast";
import { GeneralSettingsContext } from "../contexts/GeneralSettingsContext";
import { getSettingValueByKey } from "../utils/settingsUtils";
import $axios from "../axios";


const ContactMain = () => {
  const form = useRef();
  const settings = useContext(GeneralSettingsContext);
  const [formData, setFormData] = useState({});

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  if (!settings) {
    return null;
  }

  const sendEmail = async (e) => {
    e.preventDefault();
    try{
      // console.log(formData);
      // return false;
      const response = await $axios.post('/contact/send-mail',formData);
      if (response.status === 200) {
        setFormData('');
        form.current.reset();
        toast.success("Massage Sent Successfully!");
      }else{
        toast.error("Massage not sent.");
      }
    }catch (error) {
      toast.error("Massage not sent.");
      console.error('Error contact:', error);
    }
  };
  return (
    <>
      {/* ================= Contact Main start =================*/}
      <Toaster position='bottom-center' reverseOrder={false} />
      <div className='contact-area pd-top-120 pd-bottom-120'>
        <div className='container'>
          <div className='contact-page-inner bg-gray'>
            <div className='section-title mb-4 pb-2'>
              <h2 className='title'>{getSettingValueByKey(settings,'contact_head')}</h2>
              <p className='content mb-0'> {getSettingValueByKey(settings,'contact_title')}</p>
            </div>
            <form ref={form} onSubmit={sendEmail}>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='single-input-inner'>
                    <input
                      id='name'
                      name='name'
                      type='text'
                      placeholder='Enter Your Name.'
                      required
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className='col-md-12'>
                  <div className='single-input-inner'>
                    <input
                      id='email'
                      name='email'
                      type='email'
                      placeholder='Enter Your Email.'
                      required
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className='col-md-12'>
                  <div className='single-input-inner'>
                    <input
                      id='subject'
                      name='subject'
                      type='text'
                      placeholder='Enter Your Subject.'
                      required
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className='col-12'>
                  <div className='single-input-inner'>
                    <textarea
                      name='message'
                      id='massage'
                      cols='1'
                      rows='5'
                      placeholder='Enter Your Massage ...'
                      required
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className='col-12 text-center'>
                  <button
                    className='btn btn-base border-radius-5'
                    type='submit'
                  >
                    Send Message
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* contact list */}
      <div className='contact-page-list'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-4 col-md-6'>
              <div className='media single-contact-list'>
                <div className='media-left'>
                  <img src='assets/img/icon/13.svg' alt='img' />
                </div>
                <div className='media-body'>
                  <h5>Contacts us</h5>
                  <h6>{getSettingValueByKey(settings,'contact_number')}</h6>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div className='media single-contact-list'>
                <div className='media-left'>
                  <img src='assets/img/icon/14.svg' alt='img' />
                </div>
                <div className='media-body'>
                  <h5>Your Email</h5>
                  <h6>{getSettingValueByKey(settings,'contact_email')}</h6>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div className='media single-contact-list'>
                <div className='media-left'>
                  <img src='assets/img/icon/15.svg' alt='img' />
                </div>
                <div className='media-body'>
                  <h5>Location</h5>
                  <h6>{getSettingValueByKey(settings,'contact_address')}</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Map  */}
      <div className='contact-g-map'>
        <iframe src={getSettingValueByKey(settings,'contact_map')} />
      </div>

      {/* ================= Contact Main End =================*/}
    </>
  );
};

export default ContactMain;
