import $axios from "../axios";

export const fetchTeam = async () => {
  try {
    const response = await $axios.get('/team');
    return response.data;
  } catch (error) {
    console.error('Error fetching Team:', error);
    return [];
  }
};