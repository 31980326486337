import React, { useContext } from "react";
import { FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";
import { GeneralSettingsContext } from "../../../contexts/GeneralSettingsContext";
import { getSettingValueByKey } from "../../../utils/settingsUtils";


const BannerThree = ({webimage}) => {

  const settings = useContext(GeneralSettingsContext);

  if (!settings) {
    return null;
  }

  return (
    <>
      {/* page title start */}
      <div
        className="banner-area bg-relative banner-area-1 banner-area-8 pd-bottom-100 bg-cover"
        style={{ backgroundImage: 'url("./assets/img/home-9/2.png")' }}
      >
        <div className="container pt-xl-5 pb-xl-5">
          <div className="row">
            <div className="col-lg-6 align-self-center">
              <div className="banner-inner pe-xl-5 pt-4 pt-lg-0">
                <h2
                  className="title text-white wow animated fadeInLeft"
                  data-wow-duration="1.5s"
                  data-wow-delay="0.4s"
                >
                  {getSettingValueByKey(settings, 'banner_head')}
                </h2>
                <h6
                  className="content text-white wow animated fadeInLeft"
                  data-wow-duration="1.5s"
                  data-wow-delay="0.4s"
                >
                  {getSettingValueByKey(settings, 'banner_title')}
                </h6>
                <p
                  className="content pe-xl-5 wow animated fadeInLeft"
                  data-wow-duration="1.5s"
                  data-wow-delay="0.5s"
                >
                  {getSettingValueByKey(settings, 'banner_detail')}
                </p>
                <Link
                  className="btn btn-base bg-base-9 wow animated fadeInLeft"
                  data-wow-duration="1.5s"
                  data-wow-delay="0.6s"
                  to="/login"
                >
                  Sign In <FaPlus className="mt-4" />
                </Link>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="mt-5 margin-right--150 mt-lg-0 wow animated fadeInRight"
                data-wow-duration="1.5s"
                data-wow-delay="0.3s"
              >
                <img
                  className="main-img"
                  src={webimage.banner_image_url}
                  alt="img"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* banner end */}
    </>
  );
};

export default BannerThree;
