import React from "react";
import CountUp from "react-countup";
import TrackVisibility from "react-on-screen";


const CounterAreaThree = ({counter}) => {

  return (
    <>
      {/* counter area start */}
      <div
        className='counter-area bg-relative bg-cover pd-top-110 pd-bottom-100'
        style={{ backgroundImage: 'url("./assets/img/bg/5.png")' }}
      >
        <div className='container pd-bottom-90'>
          <div className='row'>

            {counter.map((data,index)=>(
                <div key={index}
                  className='col-lg-3 col-sm-6'
                  data-aos='fade-up'
                  data-aos-delay='100'
                  data-aos-duration='1500'
                >
                  <div className='single-counter-inner'>
                    <div className='thumb'>
                      <img src={data.image_url} alt='img' />
                    </div>
                    <h2 className='text-white mt-4 mb-2'>
                      <TrackVisibility once>
                        {({ isVisible }) =>
                          isVisible && (
                            <span className='counter'>
                              <CountUp duration={1} delay={0} start={0} end={data.detail} />{" "}
                              +
                            </span>
                          )
                        }
                      </TrackVisibility>
                    </h2>
                    <p className='text-white'>{data.name}</p>
                  </div>
                </div>
            ))}
            
          </div>
        </div>
      </div>
      {/* counter area end */}
    </>
  );
};

export default CounterAreaThree;


{/* <div className="counter-area bg-black-ico bg-relative pd-top-110">
  <div className="container">
    <div className="bg-base-9 border-radius-5 pt-xl-5 pt-4 position-relative z-index-2 mg-bottom---120">
      <div className="row">
      {counter.map((data,index)=>(
        <div
          className="col-lg-3 col-md-6 wow animated fadeInUp"
          data-wow-duration="1.5s"
          data-wow-delay="0.4s"
        >
          <div className="single-counter-inner text-center">
            <div className="thumb">
            <img src={data.image_url} alt={data.name} />
            </div>
            <h2 className="text-white mt-4 mb-2">
              <TrackVisibility once>
                {({ isVisible }) =>
                  isVisible && (
                    <span className="counter">
                      <CountUp delay={0} start={0} end={data.detail} /> +
                    </span>
                  )
                }
              </TrackVisibility>
            </h2>
            <p className='text-white'>{data.name}</p>
          </div>
        </div>
        ))}
      </div>
    </div>
  </div>
</div> */}
