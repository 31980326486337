import React, { useContext, useEffect, useState } from "react";
import { GeneralSettingsContext } from "../contexts/GeneralSettingsContext";
import { getSettingValueByKey } from "../utils/settingsUtils";
import { fetchSteps } from "../scripts/stepList";
import WorkProcessTemOne from "./templates/one/WorkProcessTemOne";
import WorkProcessThree from "./templates/three/WorkProcessThree";
import WorkProcessTwo from "./templates/two/WorkProcessTwo";

const WorkProcessOne = () => {
  const settings = useContext(GeneralSettingsContext);
  const [steps, setSteps] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchSteps();
      setSteps(data);
     
    };

    fetchData();
  }, []);

  const renderTemplate = () => {
    switch (getSettingValueByKey(settings,'template_status')) {
      case '1':
        return <WorkProcessTemOne steps={steps} settings={settings} />;
      case '2':
        return <WorkProcessTwo steps={steps} settings={settings} />;
      case '3':
          return <WorkProcessThree steps={steps} settings={settings} />;
      default:
        return <WorkProcessTemOne steps={steps} settings={settings}/>;
    }
  };

 if (!settings) {
  return null;
  }
  
  return (
    <>
    {renderTemplate()}
  </>
  );
};

export default WorkProcessOne;
