import React from "react";
import { getSettingValueByKey } from "../../../utils/settingsUtils";

const WorkProcessTwo = ({settings, steps}) => {
  
  return (
    <>
      {/*==================== Work Process Three start ====================*/}
      <div className='work-process-area pd-top-120 pd-bottom-120'>
        <div className='container'>
          <div className='section-title text-center pb-5'>
            <h6 className='color-base mb-3 bg-none'>{getSettingValueByKey(settings,'step_head')}</h6>
            <h2 className='title'>{getSettingValueByKey(settings,'step_title')}</h2>
          </div>
          <div className='row'>
            {steps.map((data,index)=>(
            <div className='col-lg-3 col-md-6' key={index}>
              <div className='single-work-process-inner-4 text-center'>
                <h5>{data.name}</h5>
                <div className='thumb'>
                    <img src={data.image_url} alt={data.name} />
                </div>
                <span className='count'>{data.step_name}</span>
              </div>
            </div>
             ))}
          </div>
        </div>
      </div>

      {/* ==================== Work Process Three end ====================*/}
    </>
  );
};

export default WorkProcessTwo;
