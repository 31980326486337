import React from "react";
import { FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";
import { getSettingValueByKey } from "../../../utils/settingsUtils";

const ServiceAreaThree = ({services, settings}) => {
  if (!settings) {
    return null;
  }
  return (
    <>
    {/* service area start */}
    <div className="service-area service-area_8 bg-relative bg-black-2-ico pd-top-60 pb-5">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="section-title style-white text-center">
              <h6 className="sub-title line-double text-white">
                {getSettingValueByKey(settings,'service_head')}
              </h6>
              <h2 className="title">{getSettingValueByKey(settings,'service_title')}</h2>
            </div>
          </div>
        </div>
        <div className="row">
          
            {services.map((data, index) => (
                <div className='col-lg-4 col-md-6' key={index}>
                <div className='single-service-inner bg-transparent text-center btn-border-base'>
                    <div className='thumb'>
                    <img src={data.image_url} alt='img' />
                    </div>
                    <div className='details'>
                    <h5 className="text-white">
                        <Link to={`/service/${data.id}`}>{data.name}</Link>
                    </h5>
                    <p className="text-white">{data.short_detail}</p>
                    <Link className='btn btn-border-base text-white' to={`/service/${data.id}`}>
                        Touch More <FaPlus />
                    </Link>
                    </div>
                </div>
                </div>
            ))}

          {/* <div className="col-lg-4 col-md-6">
            <div className="single-service-inner bg-transparent text-center">
              <div className="thumb">
                <img src="assets/img/home-9/7.png" alt="img" />
              </div>
              <div className="details">
                <h5 className="text-white">
                  <Link to="/service-details">Electrical Services</Link>
                </h5>
                <p className="text-white">
                  Lorem is Ipsum is simply is design iomyi is text Lorem Ipsum
                  is simply is our busi Lorem is Ipsum is
                </p>
                <Link
                  className="read-more-text text-white"
                  to="/service-details"
                >
                  Read More <FaPlus />
                </Link>
              </div>
            </div>
          </div> */}
         
        </div>
      </div>
    </div>
    {/* service area end */}
  </>
  );
};

export default ServiceAreaThree;
