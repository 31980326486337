import React from "react";
import { FaFacebookF, FaInstagram, FaTwitter } from "react-icons/fa";
import { Link } from "react-router-dom";
import { getSettingValueByKey } from "../../../utils/settingsUtils";

const TeamAreaTwo = ({settings, teams}) => {

  return (
    <>
    {/*====================== team area start ======================*/}
    <div
      className='team-area bg-relative pd-top-60 pd-bottom-80'
      style={{ backgroundImage: 'url("./assets/img/home-6/17.png")' }}
    >
      <div className='container'>
        <div className='section-title text-center style-white'>
          <h6 className='text-white mb-3 bg-none'>{getSettingValueByKey(settings,'team_head')}</h6>
          <h2 className='title'>{getSettingValueByKey(settings,'team_title')}</h2>
        </div>
        <div className='row'>
        {teams.map((data,index) => (
          <div className='col-lg-4 col-md-6' key={index}>
            <div className='single-team-inner style-3 text-center'>
              <div className='thumb'>
                <img src={data.image_url} alt={data.name} />
                <ul className='team-social-inner'>
                <li>
                    <a href={data.fb_link}>
                    <FaFacebookF />
                    </a>
                </li>
                <li>
                    <a href={data.twitter_link}>
                    <FaTwitter />
                    </a>
                </li>
                <li>
                    <a href={data.insta_link}>
                    <FaInstagram />
                    </a>
                </li>
                </ul>
              </div>
              <div className='details'>
              <h5>{data.name}</h5>
              <p>{data.designation}</p>
              </div>
            </div>
          </div>
        ))}
        </div>
      </div>
    </div>

    {/* ====================== team area end ======================*/}
  </>
  );
};

export default TeamAreaTwo;
