import React, { Children, cloneElement, useEffect, useState } from "react";
import NavBar from "../NavBar";
import FooterOne from "../FooterOne";
import $axios from "../../axios";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../../contexts/CustomerAuthContext";

const FrontProtectedLayout = ({ children }) => {

  const [loading, setLoading] = useState(true);
  const { user , setUser } = useAuth();

  useEffect(()=>{ 
   
    if(!user){
        localStorage.removeItem('user');
        window.location.href = '/login';
    }else{
        setLoading(false);
    } 
  },[]); 

  if (!user) {
    return <Navigate to="/login" />;
  }

    return (
      <>
      
        {loading ? (
           <div className="preloader" id="preloader">
              <div className="preloader-inner">
                  <div className="spinner">
                      <div className="dot1"></div>
                      <div className="dot2"></div>
                  </div>
              </div>
          </div>
        ) : (
          <Outlet/>
        )}
       
      </>
    );
  };
  
  export default FrontProtectedLayout;