import React, { useEffect, useState } from "react";
import {
  FaAngleLeft,
  FaAngleRight,
  FaCalendarAlt,
  FaChevronRight,
  FaFacebookF,
  FaRegFolderOpen,
  FaInstagram,
  FaPlus,
  FaTwitter,
  FaRegUser,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import $axios from "../axios";
import ReactHtmlParser from 'html-react-parser';

const BlogGroup = ({blog}) => {

  const [blogCat, setBlogCat] = useState([]);
  const apiUrl = process.env.REACT_APP_BACKEND_URL;

  useEffect(()=>{
    fetchBlogCat();
  },[]);

  const fetchBlogCat = async () =>{
    const response = await $axios.get('/blog-category/');
    setBlogCat(response.data);

  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  };

  return (
    <>
      {/* =============== Blog Group start ===============*/}
      <div className='blog-area pd-top-120 pd-bottom-120'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-8'>

              {blog.blogs && blog.blogs.map((data,index)=>(
                <div key={index} className='single-blog-inner'>
                  
                  {data.image && (
                    <div className='thumb'>
                      <img src={`${apiUrl}/storage/${data.image}`} alt='img' />
                      <span className='date'> {formatDate(data.updated_at)}</span>
                    </div>
                  )}
                 
                  <div className='details'>
                    <ul className='blog-meta'>
                      <li>
                        <FaRegUser /> By Admin
                      </li>
                      <li>
                        <FaRegFolderOpen /> {blog.name}
                      </li>
                    </ul>
                    <h2 className='title'>
                      <Link to={`/blog-detail/${data.id}`}>{data.name}</Link>
                    </h2>
                    <p>{ReactHtmlParser(`${data.detail.slice(0,200)}`)}</p>
                    <Link className='btn btn-border-base mt-3' to={`/blog-detail/${data.id}`}>Read More <FaPlus /></Link>
                  </div>
                </div>
              ))}
             
            </div>
            <div className='col-lg-4 col-12'>
              <div className='td-sidebar'>
                
                <div className='widget widget_catagory'>
                  <h4 className='widget-title'>Catagory</h4>
                  <ul className='catagory-items'>
                    {blogCat && blogCat.map((data,index)=>(
                      <li key={index}>
                        <Link to={`/blog-category/${data.id}`}>{data.name} <span>{data.blogs_count}</span></Link>
                      </li>
                    ))}
                  </ul>
                </div>
               
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* =============== Blog Group End ===============*/}
    </>
  );
};

export default BlogGroup;
