import React from "react";
import { FaCalendarAlt, FaUserAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import { getSettingValueByKey } from "../../../utils/settingsUtils";
import ReactHtmlParser from 'html-react-parser';

const BlogAreaTwo = ({settings, blogs}) => {

const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
};

  return (
    <>
      {/*===================== Blog Area  start =====================*/}
      <div className="blog-area pd-top-115 pd-bottom-90">
        <div className="container">
          <div className="section-title">
            <div className="row">
              <div className="col-xl-4 col-lg-8">
                <h6 className="color-base">{getSettingValueByKey(settings,'blog_head')}</h6>
                <h2 className="title">{getSettingValueByKey(settings,'blog_title')}</h2>
              </div>
            </div>
          </div>
          <div className="row">
          {blogs.map((data,index)=>(
            <div className="col-lg-4 col-md-6" key={index}>
              <div className="single-blog-list">
                <div className="details">
                  <ul className="blog-meta ps-0">
                    <li>
                      <FaUserAlt /> By Admin
                    </li>
                  </ul>
                  <h5 className="mb-3">
                    <Link to={`/blog-detail/${data.id}`}>{data.name}</Link>
                  </h5>
                  <p>
                  {ReactHtmlParser(data.detail.slice(0,100))}
                  </p>
                </div>
                <div className="thumb">
                <img src={data.image_url} alt={data.name} />
                  <Link className="date-2" to={`/blog-detail/${data.id}`}>
                    {formatDate(data.updated_at)}
                  </Link>
                </div>
              </div>
            </div>
             ))}
          </div>
        </div>
      </div>

      {/* ===================== BlogAreaFour End =====================*/}
    </>
  );
};

export default BlogAreaTwo;
