
import React from "react";
import { Link } from "react-router-dom";
import $axios from "../../../axios";
import toast from "react-hot-toast";


const CustomerSideBar = ({customer}) => {

    const handleLogout = async (e) => {
        e.preventDefault();
        localStorage.removeItem('user');
        await $axios.post('/customer/logout');
        toast.success('Logout successful. See you next time!');
        setTimeout(()=>{
            window.location.href = '/';
        },3000);
      };
  
  return (
    <>
      <div class="user-widget">
            <div class="user-widget-body text-center">
              <h5 class="mt-3">{customer.name}</h5>
              <span className='badge badge-success'>Active</span>
              <p>Joined At : {new Date(customer.created_at).toLocaleDateString()}</p>
            </div>
            <ul class="user-menu list-style-none">
            <li class="active"><Link to={'/customer-dashboard'}> <i class="fas fa-layer-group"></i> Dashboard </Link></li>
            <li><Link to={'/customer-orders'}> <i class="fas fa-layer-group"></i> Orders </Link></li>
            <li><Link to={'/customer-profile'}> <i class="fas fa-layer-group"></i> Profile </Link></li>
            <li><a href="#0" onClick={handleLogout}><i class="fas fa-sign-out-alt"></i> Logout</a></li>
            </ul>
        </div>
    </>
  );
};

export default CustomerSideBar;
