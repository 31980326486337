import React from "react";
import Breadcrumb from "../../components/Breadcrumb";
import Layout from "../../components/layout/FrontLayout";
import ResetPassword from "../../components/customer/ResetPassword";

const ResetPasswordMain = () => {
  
  return (
    <>
     <Layout>
        {/* Navigation Bar */}
        <Breadcrumb title={"Reset Password"} />

        <ResetPassword />
     </Layout>
    </>
  );
};

export default ResetPasswordMain;
