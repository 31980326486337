import React from "react";
import { FaAngleDoubleRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import { getSettingValueByKey } from "../../../utils/settingsUtils";

const CaseStudyAreaThree = ({settings, gallery}) => {

    return (
        <>
          {/* gallery area start */}
          <div className="gallery-area gallery-area_1 bg-black-ico bg-relative pd-top-60">
            <div className="container">
              <div className="section-title style-white">
                <div className="row">
                  <div className="col-md-8">
                    <h6 className="sub-title text-white">{getSettingValueByKey(settings,'gallery_head')}</h6>
                    <h2 className="title">{getSettingValueByKey(settings,'gallery_title')}</h2>
                  </div>
                </div>
              </div>
              <div className="row">
              {gallery.map((data,index) => (
                <div className="col-lg-4 col-md-6">
                  <div className="single-case-study-inner style-3">
                    <div className="thumb  img-fit-container">
                        <img src={data.image_url} alt={data.name} />
                    </div>
                    <div className="details">
                      <h5>
                        <Link to={`/gallery/${data.id}`}>{data.name}</Link>
                      </h5>
                    <Link className='cat' to={`/gallery/${data.id}`}>{data.short_detail.slice(0,50)}</Link>
                    <Link className='right-arrow' to={`/gallery/${data.id}`}><FaAngleDoubleRight /></Link>
                    </div>
                  </div>
                </div>
                ))}
              </div>
            </div>
          </div>
          {/* gallery area end */}
        </>
    );
};

export default CaseStudyAreaThree;
