import React from "react";
import { getSettingValueByKey } from "../../../utils/settingsUtils";

const WorkProcessThree = ({settings, steps}) => {

    const chunkArray = (array, chunkSize) => {
        const result = [];
        for (let i = 0; i < array.length; i += chunkSize) {
          result.push(array.slice(i, i + chunkSize));
        }
        return result;
    };
    const chunkedSteps = chunkArray(steps, 3);

  return (
    <>
      {/* roadmap area start */}
      <div className="roadmap-area roadmap-area_1 bg-relative bg-black-ico pd-top-60 pb-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="section-title style-white text-center">
                <h6 className="sub-title line-double text-white">{getSettingValueByKey(settings,'step_head')}</h6>
                <h2 className="title">{getSettingValueByKey(settings,'step_title')}</h2>
              </div>
            </div>
          </div>

          {chunkedSteps.map((stepChunk, index) => (
          <div className="roadmap-line mt-5" key={index}>
            <div className="row">
            {stepChunk.map((data, subIndex) => (
              <div className="col-lg-4 col-md-6" key={subIndex}>
                <div className="single-service-inner bg-transparent text-center">
                  <div className="thumb">
                    <img src={data.image_url} alt={data.name} />
                  </div>
                  <div className="details">
                    <p className='process-count text-white'>{data.step_name}</p>
                    <h5 className="text-white">{data.name}</h5>
                    <p className="text-white">
                    {data.detail}
                    </p>
                  </div>
                </div>
              </div>
               ))}
            </div>
          </div>
         ))}
        </div>
      </div>
      {/* roadmap area end */}
    </>
  );
};

export default WorkProcessThree;
