import $axios from "../axios";

export const fetchServices = async () => {
  try {
    const response = await $axios.get('/service');
    return response.data;
  } catch (error) {
    console.error('Error fetching services:', error);
    return [];
  }
};