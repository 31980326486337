import React from "react";
import { FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";

const CaseStudyArea = ({gallery}) => {

  return (
    <>
      {/* ====================  Case Study Area start ====================*/}
      <div className='service-area pd-top-120 pd-bottom-90'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-4 col-12'>
            <div className='td-sidebar service-sidebar'>
                
                <div className='widget widget_catagory'>
                  <h5 className='widget-title'>
                    <FaArrowRight /> All Gallery lists
                  </h5>
                  <ul className='catagory-items'>
                    {gallery.gallery_detail && gallery.gallery_detail.map((data, index) => (
                      <li key={index}>
                        <Link to={`/gallery-detail/${data.id}`}> {data.name}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
                
              </div>
            </div>
            <div className='col-lg-8'>
              <div className='blog-details-page-content'>
                <div className='single-blog-inner mb-0'>
                  <div className='thumb'>
                    <img src={gallery.image_url} alt='img' />
                  </div>
                  <div className='details'>
                    <h4>{gallery.name}</h4>
                    <p>{gallery.short_detail}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ====================  Case Study Area End ====================*/}
    </>
  );
};

export default CaseStudyArea;
