import React, { useEffect, useState } from "react";
import Breadcrumb from "../../components/Breadcrumb";
import CaseStudyArea from "../../components/CaseStudyArea";
import FooterOne from "../../components/FooterOne";
import NavBar from "../../components/NavBar";
import Layout from "../../components/layout/FrontLayout";
import { useParams } from "react-router-dom";
import $axios from "../../axios";

const CaseStudyDetails = () => {
  const {id} = useParams();
  const [detail, setDetails] = useState({});

  useEffect(()=>{
    fetchData();
  },[id]);

  const fetchData = async () =>{
      const response = await $axios.get('/gallery/'+id);
      setDetails(response.data);
  }

  return (
    <>
      <Layout>
        {/* Navigation Bar */}
        <Breadcrumb title={detail.name} />
        {/* Case Study Area */}
        <CaseStudyArea gallery={detail} />
      </Layout>
    </>
  );
};

export default CaseStudyDetails;
