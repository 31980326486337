import React, { useContext } from "react";
import { FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";
import { GeneralSettingsContext } from "../../../contexts/GeneralSettingsContext";
import { getSettingValueByKey } from "../../../utils/settingsUtils";

const BannerTwo = ({webimage}) => {

  const settings = useContext(GeneralSettingsContext);

  if (!settings) {
    return null;
  }

  return (
    <>
      {/* ================== BannerFive Start ==================*/}
      <div
        className="banner-area bg-relative banner-area-2 bg-cover mt-0"
        style={{ backgroundImage: `url(${webimage.banner_image_url})` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-5 pb-xl-5 align-self-center">
              <div className="banner-inner pe-xl-4 pb-5">
                <h6
                  className="bg-none text-white    mb-4"
                  data-aos="fade-right"
                  data-aos-delay="100"
                  data-aos-duration="1500"
                >
                  {getSettingValueByKey(settings, 'banner_head')}
                </h6>
                <h2
                  className="title text-white   "
                  data-aos="fade-right"
                  data-aos-delay="200"
                  data-aos-duration="1500"
                >
                  {getSettingValueByKey(settings, 'banner_title')}
                </h2>
                <p
                  className="content pe-xl-4   "
                  data-aos="fade-right"
                  data-aos-delay="300"
                  data-aos-duration="1500"
                >
                  {getSettingValueByKey(settings, 'banner_detail')}
                </p>
                <Link
                  className="btn btn-base text_inner_white "
                  data-aos="fade-right"
                  data-aos-delay="400"
                  data-aos-duration="1500"
                  to="/login"
                >
                  Sign In
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ================== BannerFive End ==================*/}
    </>
  );
};

export default BannerTwo;
