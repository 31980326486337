import React, { useContext, useEffect, useState } from "react";
import CountUp from "react-countup";
import TrackVisibility from "react-on-screen";
import { fetchCounters } from "../scripts/counterList";
import { getSettingValueByKey } from "../utils/settingsUtils";
import { GeneralSettingsContext } from "../contexts/GeneralSettingsContext";
import CounterAreaTemOne from "./templates/one/CounterAreaTemOne";
import CounterAreaTwo from "./templates/two/CounterAreaTwo";
import CounterAreaThree from "./templates/three/CounterAreaThree";

const CounterAreaOne = () => {
  const [counter, setCouter] = useState([]);
  const settings = useContext(GeneralSettingsContext);
  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchCounters();
      setCouter(data);
     
    };

    fetchData();
  }, []);

  const renderTemplate = () => {
    switch (getSettingValueByKey(settings,'template_status')) {
      case '1':
        return <CounterAreaTemOne counter={counter} />;
      case '2':
        return <CounterAreaTwo counter={counter} />;
      case '3':
          return <CounterAreaThree counter={counter} />;
      default:
        return <CounterAreaTemOne counter={counter} />;
    }
  };

  if (!settings) {
    return null;
  }

  return (
    <>
       {renderTemplate()}
    </>
  );
};

export default CounterAreaOne;
