import $axios from "../axios";

export const fetchSection = async () => {
  try {
    const response = await $axios.get('/section');
    return response.data;
  } catch (error) {
    console.error('Error fetching Section:', error);
    return [];
  }
};