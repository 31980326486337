import React, { useContext, useEffect, useState } from "react";
import Breadcrumb from "../components/Breadcrumb";
import CaseStudyArea from "../components/CaseStudyArea";
import FooterOne from "../components/FooterOne";
import NavBar from "../components/NavBar";
import { Link, useLocation, useParams } from "react-router-dom";
import $axios from "../axios";
import Layout from "../components/layout/FrontLayout";
import { FaArrowLeft, FaArrowRight, FaTrash } from "react-icons/fa";
import { GeneralSettingsContext } from "../contexts/GeneralSettingsContext";
import { getSettingValueByKey } from "../utils/settingsUtils";
import { useForm } from "react-hook-form";
import { toast, Toaster } from "react-hot-toast";
import { useAuth } from "../contexts/CustomerAuthContext";
import Modal from 'react-bootstrap/Modal';

const CartPayment = ({webimage}) => {

  const settings = useContext(GeneralSettingsContext);
  const [cartProducts, setCartProducts] = useState([]);
  const [countries, setCountries] = useState([]);
  const [gateways, setGateway] = useState([]);
  const [formData, setFormData] = useState({});
  const [subTotal, setSubtotal] = useState('');
  const [totalDiscount, setTotalDis] = useState('');
  const [gatewayId, setGatewayId] = useState('');
  const [gatewayDetail, setGatewayDetail] = useState({});
  const [delFee, setDelFee] = useState('');
  const { register, clearErrors, handleSubmit, setError, reset, formState: { errors } , watch} = useForm();
  const user = useAuth();
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUrl, setImageUrl] = useState('');
  const watchedFields = watch(); 
  const [paymentTransId, setPaymentTransId] = useState(null);
  const [manualPaymentDetails, setManualPaymentDetails] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData();
    if(gatewayId > 50){
        handleShowModal();
        return;
    }
  }, [gatewayId]);

  const fetchData = async () => {
        await carts();
        await getCountries();
        cal();
        fetchGatewayData();
    };

    const fetchGatewayData = async () =>{
        const response = await $axios.get('/gateway');
        setGateway(response.data);
    }

  const carts = async () => {
    setCartProducts(JSON.parse(localStorage.getItem('cartItems')));
  };
 
  const getDel = async () => {
    if (settings) {
        setDelFee(getSettingValueByKey(settings,'delivery_fee'));
    }else{
        setDelFee('0');
    }
  };

  const handleChangeTransId = (e) => {
    setPaymentTransId(e.target.value.trim());
  }

  const handleChangeManualDetails = (e) => {
    setManualPaymentDetails(e.target.value.trim());
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value.trim() });

    if (name === 'gateway_id'){
        setGatewayId(value.trim());
        reset({ gateway_id: value.trim() });
        if(gatewayId > 50){
            handleShowModal();
            return;
        }
    }
    

    // setFormData({ ...formData, [e.target.name]: e.target.value.trim() });
    // if(e.target.name === 'gateway_id'){
    //     setGatewayId(e.target.value);
    //     reset({ gateway_id: e.target.value });

    //     if(gatewayId > 50){
    //         handleShowModal();
    //         return;
    //     }
    // }
  };

  const getCountries = async () => {
    const response = await fetch('/json/countries.json');
    const data = await response.json();
    setCountries(data);
  };

  const handleChangeImage = (e) => {
        const file = e.target.files[0];
        if (file) {
            const allowedTypes = ["image/jpeg", "image/png", "image/gif", "image/jpg", "image/heic", "image/heif"];
            if (!allowedTypes.includes(file.type)) {
                setError("proof_image", { type: "manual", message: "Only image files are allowed." });
                setSelectedImage(null);
                return;
            }
            
            clearErrors("proof_image");
            setSelectedImage(file);

            const reader = new FileReader();
            reader.onload = function(event) {
                const imageUrl = event.target.result;
                setImageUrl(imageUrl);
            };
            reader.readAsDataURL(file);
        } else {
            setError("proof_image", { type: "manual", message: "Image selection failed. Please try again." });
        }
    }



    const handleModalSubmit = async (e) => {
        e.preventDefault();
        onSubmit(watchedFields);
        // onSubmit(formData)
    };

  const onSubmit = async (formData) => {
    // e.preventDefault();
    setLoading(true); 
    try{
        // formData['item'] = cartProducts;
        // formData['gateway_id'] = gatewayId;
        // formData['proof_image'] = selectedImage;

        const completeFormData = {
            ...formData,
            item: cartProducts,
            gateway_id: gatewayId,
            proof_image: selectedImage,
            payment_trans_id: paymentTransId,
            manual_payment_details: manualPaymentDetails,
          };

       

        if(gatewayId > 50){
            if (!paymentTransId) {
                setError("payment_trans_id", { type: "manual", message: "Payment Transaction ID is required." });
                return;
            }else{
                clearErrors("payment_trans_id");
            }
            if (!selectedImage) {
                setError("proof_image", { type: "manual", message: "Payment Proof Image is required." });
                return;
            }else{
                clearErrors("proof_image");
            }
        }

        const response = await $axios.post('/submit-order',completeFormData,{
            headers: {
                'Content-Type': 'multipart/form-data'
              }
        });
      
        if (response.status === 200) {

            if(response.data.success && response.data.type && response.data.type === "form"){
                const container = document.getElementById('form-container');
                // Set the form HTML
                container.innerHTML = response.data.redirect_url;
                // Submit the form
                document.getElementById('payment-form').submit();
                localStorage.removeItem('cartItems');
                return false;
            }

            if(response.data.success){
                localStorage.removeItem('cartItems');
                window.location.href = response.data.redirect_url;
                return false;
            }else{
                toast.error(response.data?.message);
              }
              
          }else{
            toast.error("Please try again, Something went wrong");
          }
    }catch (error) {
        if (error.response.data.error) {
            for (const [key, messages] of Object.entries(error.response?.data.error)) {
              setError(key, { type: 'server', message: messages[0] });
            }
        }else{
            const errorMessage = error.response?.status === 422 ? 
            Object.values(error.response.data.error).flat()[0] :
            error.response?.data.error || 'Something went wrong';
            console.error('Error Order Submit:', errorMessage);
        }
            
    }finally {
        setLoading(false); 
    }
    
  }

  const handleShowModal = () => {
        let selectedGateway = gateways.find((gateway) => gateway.id == gatewayId);
        setGatewayDetail({
            name : selectedGateway.name,
            detail : selectedGateway.gateway_key_one,
        }); 
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
      
    };

  const cal = () => {
    var totalDiscount = 0;
    var subTotal = 0;

    if (cartProducts && cartProducts.length > 0) {
        cartProducts.forEach((item) => {
            if (item.item && item.item.price && item.item.discount_amount) {
                if(item.item.price > 0){
                    subTotal += parseFloat(item.item.price)*parseFloat(item.qty);
                }else{
                    subTotal = 0;
                }

                if(item.item.discount_amount > 0){
                    totalDiscount += parseFloat(0);
                    // totalDiscount += parseFloat(item.item.discount_amount);
                }else{
                    totalDiscount = 0;
                }
            }
        });
        setSubtotal(subTotal);
        setTotalDis(totalDiscount);
        getDel();
    }
  };

    useEffect(() => {
        cal();
    }, [cartProducts]); // Recalculate when cartProducts change

    useEffect(() => {
        cal();
    }, [settings]); // Recalculate when settings change
  
  if (!settings) {
    return null;
  }

 

  return (
    <>
      <Layout>

        {/* Navigation Bar */}
        <Breadcrumb title={'Cart Payment'} webimage={webimage} />

        <section className="cart-personal pd-top-120 pd-bottom-120">
            <div id="form-container"></div>
            <div className="container container-two">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row gy-5">
                        <div className="col-lg-6 pe-sm-5">
                            <div className="cart-personal__content bg-gray p-lg-4 p-2 rounded ">
                                <h4 className=" mb-4 cart-personal__title mb-32">Billing Address</h4>
                                    <div className="mb-4 single-input-inner">
                                        <label for="name" className="form-label font-16 mb-2 fw-500 font-heading">
                                            Full Name <span className="text-danger">*</span>
                                        </label>
                                        <input
                                            value={user?.user?.user?.name ?? null}
                                            type="text"
                                            className="common-input"
                                            style={errors.name ? { border: "1px solid red" } : {}}
                                            id="name"
                                            placeholder="Name"
                                            name="name"
                                            maxLength={255}
                                            onChange={handleChange}
                                            {...register("name", {
                                            required: "This field is required.",
                                            maxLength: {
                                                value: 255,
                                                message: "This input exceeds max length 100.",
                                            },
                                            })}
                                        />
                                        {errors.name && <small className="text-danger">{errors.name?.message}</small>}
                                    </div>

                                    <div className="mb-4 single-input-inner">
                                        <label for="email" className="form-label font-16 mb-2 fw-500 font-heading">Email Address <span className="text-danger">*</span> </label>
                                        <input
                                            value={user?.user?.user?.email ?? null}
                                            type="email"
                                            className="common-input"
                                            style={errors.email ? { border: "1px solid red" } : {}}
                                            id="email"
                                            placeholder="Email"
                                            name="email"
                                            maxLength={250}
                                            onChange={handleChange}
                                            {...register("email", {
                                            required: "This field is required.",
                                            maxLength: {
                                                value: 250,
                                                message: "This input exceeds max length 100.",
                                            },
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                message: "Invalid email address."
                                            }
                                            })}
                                        />
                                        {errors.email && <small className="text-danger">{errors.email?.message}</small>}
                                    </div>
                                
                                    <div className="mb-4 single-input-inner">
                                        <label for="mobileNumber" className="form-label font-16 mb-2 fw-500 font-heading">Mobile Number <span className="text-danger">*</span> </label>
                                        <input
                                            value={user?.user?.user?.phone ?? null}
                                            type="tel"
                                            className="common-input"
                                            style={errors.mobile ? { border: "1px solid red" } : {}}
                                            id="mobile"
                                            placeholder="Mobile"
                                            name="mobile"
                                            maxLength={13}
                                            onChange={handleChange}
                                            {...register("mobile", {
                                            required: "This field is required.",
                                            maxLength: {
                                                value: 13,
                                                message: "This input exceeds max length 13.",
                                            },
                                            pattern: {
                                                value: /^\d+$/,
                                                message: "This input is number only.",
                                            },
                                            })}
                                        />
                                        {errors.mobile && <small className="text-danger">{errors.mobile?.message}</small>}
                                    </div>

                                    <div className="mb-4 single-input-inner">
                                        <label for="homeAddress" className="form-label font-16 mb-2 fw-500 font-heading">Address <span className="text-danger">*</span> </label>
                                        <input
                                            value={user?.user?.user?.address ?? null}
                                            type="text"
                                            className="common-input"
                                            style={errors.address ? { border: "1px solid red" } : {}}
                                            id="address"
                                            name="address"
                                            placeholder="eg: house , area"
                                            maxLength={191}
                                            onChange={handleChange}
                                            {...register("address", {
                                            required: "This field is required.",
                                            maxLength: {
                                                value: 191,
                                                message: "This input exceeds max length 100.",
                                            },
                                            })}
                                        />
                                        {errors.address && <small className="text-danger">{errors.address?.message}</small>}
                                    </div>

                                    <div className="mb-4 single-input-inner">
                                        <label for="stateThana" className="form-label font-16 mb-2 fw-500 font-heading">State/Thana <span className="text-danger">*</span> </label>
                                        <input
                                            value={user?.user?.user?.state ?? null}
                                            type="text"
                                            className="common-input"
                                            style={errors.state ? { border: "1px solid red" } : {}}
                                            id="state"
                                            name="state"
                                            placeholder="State"
                                            maxLength={100}
                                            onChange={handleChange}
                                            {...register("state", {
                                            required: "This field is required.",
                                            maxLength: {
                                                value: 100,
                                                message: "This input exceeds max length 100.",
                                            },
                                            })}
                                        />
                                        {errors.state && <small className="text-danger">{errors.state?.message}</small>}
                                    </div>

                                    <div className="mb-4 single-input-inner">
                                        <label for="city" className="form-label font-16 mb-2 fw-500 font-heading">City <span className="text-danger">*</span> </label>
                                        <input
                                            value={user?.user?.user?.city ?? null}
                                            type="text"
                                            className="common-input"
                                            style={errors.city ? { border: "1px solid red" } : {}}
                                            id="city"
                                            placeholder="City"
                                            maxLength={100}
                                            name="city"
                                            onChange={handleChange}
                                            {...register("city", {
                                            required: "This field is required.",
                                            maxLength: {
                                                value: 100,
                                                message: "This input exceeds max length 100.",
                                            },
                                            })}
                                        />
                                        {errors.city && <small className="text-danger">{errors.city?.message}</small>}
                                    </div>

                                    <div className="mb-4 single-input-inner">
                                        <label for="disDivision" className="form-label font-16 mb-2 fw-500 font-heading">District/Division <span className="text-danger">*</span> </label>
                                        <input
                                            value={user?.user?.user?.division ?? null}
                                            type="text"
                                            className="common-input"
                                            style={errors.division ? { border: "1px solid red" } : {}}
                                            id="division"
                                            name="division"
                                            placeholder="eg: house , area"
                                            maxLength={100}
                                            onChange={handleChange}
                                            {...register("division", {
                                            required: "This field is required.",
                                            maxLength: {
                                                value: 100,
                                                message: "This input exceeds max length 100.",
                                            },
                                            })}
                                        />
                                        {errors.division && <small className="text-danger">{errors.division?.message}</small>}
                                    </div>

                                    <div className="mb-4 single-input-inner">
                                        <label for="country" className="form-label font-16 mb-2 fw-500 font-heading">Country <span className="text-danger">*</span> </label>
                                        <select 
                                            className="form-control" 
                                            style={errors.country ? { border: "1px solid red" } : {}}
                                            name="country" 
                                            id="country" 
                                            onChange={handleChange} 
                                            {...register("country", {
                                                required: "This field is required.",
                                            })}>
                                            <option value="">Select...</option>
                                            {countries.map((data,index)=>(
                                                <option value={data.name} key={index} selected={ (user?.user?.user?.country ?? null) == data.name}>{data.name}</option>
                                            ))}
                                        </select>
                                        {errors.country && <small className="text-danger">{errors.country?.message}</small>}
                                    </div>

                                    <div className="mt-32 mb-32">
                                        <div className="common-check d-flex align-items-center gap-2 mb-2">
                                            <input className="form-check-input mt-0" type="checkbox" name="agree" id="agree" onChange={handleChange}
                                            {...register("agree", {
                                                required: "You have to agree to terms.",
                                            })}/>
                                            <label className="form-check-label mb-0" for="agree">Agree To Terms</label>
                                            {errors.agree && <small className="text-danger">* {errors.agree?.message}</small>}
                                        </div>
                                        <div className="common-check d-flex align-items-center gap-2 mb-0">
                                            <input className="form-check-input mt-0" type="checkbox" name="privacy" id="privacy"  onChange={handleChange}
                                            {...register("privacy", {
                                                required: "You have to agree to Privacy policy.",
                                            })}/>
                                            <label className="form-check-label mb-0" for="privacy">Agree To Privacy Policy</label>
                                            {errors.privacy && <small className="text-danger">* {errors.privacy?.message}</small>}
                                        </div>
                                    </div>
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="order-summary">
                                <h4 className=" mb-4 order-summary__title mb-32">Order Summary</h4>
                                <ul className="billing-list">
                                    <li className="billing-list__item d-flex align-items-center justify-content-between flex-wrap">
                                        <span className="text text-heading fw-500">You have items</span>
                                        <span className="amount text-heading fw-500">{cartProducts.length}</span>
                                    </li>
                                    <li className="billing-list__item d-flex align-items-center justify-content-between flex-wrap">
                                        <span className="text text-heading fw-500">Discount</span>
                                        <span className="amount text-body">{getSettingValueByKey(settings,'currency')} {totalDiscount}</span>
                                    </li>
                                    <li className="billing-list__item d-flex align-items-center justify-content-between flex-wrap">
                                        <span className="text text-heading fw-500">Delivery Fee</span>
                                        <span className="amount text-body">{getSettingValueByKey(settings,'currency')} {delFee}</span>
                                    </li>
                                    <li className="billing-list__item d-flex align-items-center justify-content-between flex-wrap">
                                        <span className="text text-heading fw-500">Subtotal</span>
                                        <span className="amount text-body">{getSettingValueByKey(settings,'currency')} {subTotal}</span>
                                    </li>
                                    <li className="billing-list__item d-flex align-items-center justify-content-between flex-wrap">
                                        <span className="text text-heading font-20 fw-500 font-heading">Total</span>
                                        <span className="amount text-heading font-20 fw-500 font-heading"> {getSettingValueByKey(settings,'currency')} {(parseFloat(subTotal)+parseFloat(delFee)).toFixed(2)}</span>
                                    </li>
                                </ul>

                                <div className="payment-method mt-5">
                                    <h5 className="payment-method__title mb-4">Select Payment Method</h5>
                                    <div className="row">
                                        {gateways && gateways.map((data,index) => (
                                            <div className="col-3 mt-1" key={index}>
                                                <div className="payment-method__item">
                                                    <input className="form-check-input" type="radio" name="gateway_id" id={`payment${data.id}`} value={data.id} hidden onChange={handleChange} 
                                                    />
                                                    <label className="form-check-label" htmlFor={`payment${data.id}`}>
                                                        <img className="img-fluid img-thumbnail" width={60} src={data.image_url} alt={data.name}/>
                                                    </label>
                                                </div>
                                            </div>
                                        ))}
                                        {errors.gateway_id && <small className="text-danger mt-2">* {errors.gateway_id?.message}</small>}
                                    </div>
                                </div>

                                {gatewayId && gatewayId > 50 && (
                                    <Modal show={showModal} onHide={handleCloseModal} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
                                        <Modal.Header closeButton className="cart-personal__content bg-gray p-lg-4 p-2 rounded ">
                                            <Modal.Title> <h4>Make Payment with {gatewayDetail.name}</h4> </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body className="cart-personal__content bg-gray p-lg-4 p-2 rounded ">
                                            <div className="row gy-5">
                                                
                                                <div className="col"> 
                                                    <div className="col-md-12">
                                                        <h5>Payment Instructions</h5>
                                                        <p>Please transfer the payment to the following bank account:</p>
                                                        <p>{gatewayDetail.detail}</p>
                                                        <p>Once the payment is made, please send us a copy of the payment receipt to </p>
                                                    </div>
                                                    <div className="mb-4 single-input-inner">
                                                        <label for="payment_trans_id" className="form-label font-16 mb-2 fw-500 font-heading">
                                                            Transaction ID <span className="text-danger">*</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="common-input"
                                                            style={errors.payment_trans_id ? { border: "1px solid red" } : {}}
                                                            id="payment_trans_id"
                                                            placeholder="Payment Transaction ID"
                                                            name="payment_trans_id"
                                                            maxLength={100}
                                                            onChange={handleChangeTransId}
                                                        />
                                                        {errors.payment_trans_id && <small className="text-danger">{errors.payment_trans_id?.message}</small>}
                                                    </div>

                                                    <div className="mb-4 single-input-inner">
                                                        <label for="name" className="form-label font-16 mb-2 fw-500 font-heading">
                                                            Payment Proof Image <span className="text-danger">*</span>
                                                        </label>
                                                        <input
                                                            type="file"
                                                            className="common-input"
                                                            style={errors.proof_image ? { border: "1px solid red" } : {}}
                                                            id="proof_image"
                                                            placeholder="Payment Image"
                                                            name="proof_image"
                                                            accept="image/*"
                                                            onChange={handleChangeImage}
                                                        />
                                                        {errors.proof_image && <small className="text-danger">{errors.proof_image?.message}</small>}

                                                        <div className="circle" style={{height:'100%'}}>
                                                            <img className="profile-pic" src={imageUrl ? imageUrl : '/public/images/no-image.png'} alt=""/>
                                                        </div>
                                                    </div>

                                                    <div className="mb-4 single-input-inner">
                                                        <label for="manual_payment_details" className="form-label font-16 mb-2 fw-500 font-heading">
                                                            Details 
                                                        </label>
                                                        <textarea className="common-input"
                                                            style={errors.manual_payment_details ? { border: "1px solid red" } : {}}
                                                            id="manual_payment_details"
                                                            placeholder="Payment Details (If needed)"
                                                            name="manual_payment_details"
                                                            maxLength={255}
                                                            onChange={handleChangeManualDetails}>
                                                        </textarea>
                                                        {errors.manual_payment_details && <small className="text-danger">{errors.manual_payment_details?.message}</small>}
                                                    </div>

                                                </div>
                                            </div> 
                                        </Modal.Body>
                                        <Modal.Footer className="cart-personal__content bg-gray p-lg-4 p-2 rounded ">
                                            <button type="button" onClick={handleModalSubmit} className="btn btn--black pill btn-lg w-100" disabled={loading}> 
                                                <span className="icon icon-left"><img src="assets/images/icons/cart-reverse.svg" alt=""/></span> 
                                                Purchase Now
                                            </button>
                                        </Modal.Footer>
                                    </Modal>
                                )}
                                

                                <button type="submit" className="btn btn--black pill btn-lg w-100" disabled={loading}> 
                                    <span className="icon icon-left"><img src="assets/images/icons/cart-reverse.svg" alt=""/></span> 
                                    Purchase Now
                                </button>
                                
                            </div>

                        </div>
                    </div>
                    
                </form>
            </div>
        </section>

      </Layout>
    </>
  );
};
export default CartPayment;
