import React from "react";
import Breadcrumb from "../components/Breadcrumb";
import ContactMain from "../components/ContactMain";
import FooterOne from "../components/FooterOne";
import NavBar from "../components/NavBar";
import Layout from "../components/layout/FrontLayout";

const Contact = () => {
  
  return (
    <>
     <Layout>
        {/* Navigation Bar */}
        <Breadcrumb title={"Contact"} />

        {/* Contact Main */}
        <ContactMain />
     </Layout>
    </>
  );
};

export default Contact;
