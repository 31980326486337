import React from "react";
import Breadcrumb from "../components/Breadcrumb";
import Layout from "../components/layout/FrontLayout";
import Dashboard from "../components/customer/Dashboard";
import { useAuth } from "../contexts/CustomerAuthContext";

const UserDashboard = () => {

  const { user , setUser } = useAuth();
  
  return (
    <>
     <Layout>
        {/* Navigation Bar */}
        <Breadcrumb title={"Dashboard"} />
        <Dashboard customer={user.user}/>
     </Layout>
    </>
  );
};

export default UserDashboard;
