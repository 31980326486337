import $axios from "../axios";

export const fetchFaq = async () => {
  try {
    const response = await $axios.get('/faq');
    return response.data;
  } catch (error) {
    console.error('Error fetching Faq:', error);
    return [];
  }
};