import React, { useContext, useEffect, useState } from "react";
import { GeneralSettingsContext } from "../contexts/GeneralSettingsContext";
import { getSettingValueByKey } from "../utils/settingsUtils";
import { FaCalendarAlt, FaRegComments, FaRegUser } from "react-icons/fa";
import { Link } from "react-router-dom";
import { fetchBlogs } from "../scripts/blogList";
import BlogAreaTemOne from "./templates/one/BlogAreaTemOne";
import BlogAreaTwo from "./templates/two/BlogAreaTwo";
import BlogAreaThree from "./templates/three/BlogAreaThree";

const BlogAreaOne = () => {
  const settings = useContext(GeneralSettingsContext);
  const [blogs, setBlogs] = useState([]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchBlogs();
      setBlogs(data);
     
    };

    fetchData();
  }, []);

  const renderTemplate = () => {
    switch (getSettingValueByKey(settings,'template_status')) {
      case '1':
        return <BlogAreaTemOne blogs={blogs} settings={settings} />;
      case '2':
        return <BlogAreaTwo blogs={blogs} settings={settings} />;
      case '3':
          return <BlogAreaThree blogs={blogs} settings={settings} />;
      default:
        return <BlogAreaTemOne blogs={blogs} settings={settings}/>;
    }
  };

 if (!settings) {
  return null;
  }
  return (
    <>
       {renderTemplate()}
    </>
  );
};

export default BlogAreaOne;
