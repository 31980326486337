import React, { createContext, useEffect, useState } from "react";
import $axios from "../axios";

const GeneralSettingsContext = createContext();

const GeneralSettingsProvider = ({ children }) => {
  const [settings, setSettings] = useState(null);

  useEffect(() => {
    $axios.get('/general')
      .then(response => {
        setSettings(response.data);
        document.title = response.data[0]['value'] ?? '';
      })
      .catch(error => {
        console.error('Error fetching general settings:', error);
      });
  }, []);
  

  return (
    <GeneralSettingsContext.Provider value={settings}>
      {children}
    </GeneralSettingsContext.Provider>
  );
};

export { GeneralSettingsProvider, GeneralSettingsContext };