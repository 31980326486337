import React, { useContext, useEffect, useState } from "react";
import { GeneralSettingsContext } from "../contexts/GeneralSettingsContext";
import { fetchTeam } from "../scripts/teamList";
import { getSettingValueByKey } from "../utils/settingsUtils";
import TeamAreaTemOne from "./templates/one/TeamAreaTemOne";
import TeamAreaTwo from "./templates/two/TeamAreaTwo";
import TeamAreaThree from "./templates/three/TeamAreaThree";

const TeamAreaOne = () => {

  const settings = useContext(GeneralSettingsContext);
  const [teams, setTeams] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchTeam();
      setTeams(data);
     
    };

    fetchData();
  }, []);

  const renderTemplate = () => {
    switch (getSettingValueByKey(settings,'template_status')) {
      case '1':
        return <TeamAreaTemOne teams={teams} settings={settings} />;
      case '2':
        return <TeamAreaTwo teams={teams} settings={settings} />;
      case '3':
          return <TeamAreaThree teams={teams} settings={settings} />;
      default:
        return <TeamAreaTemOne teams={teams} settings={settings}/>;
    }
  };

  if (!settings) {
    return null;
  }

  return (
    <>
      {renderTemplate()}
    </>
  );
};

export default TeamAreaOne;
