import React from "react";
import Breadcrumb from "../components/Breadcrumb";
import FooterOne from "../components/FooterOne";
import NavBar from "../components/NavBar";
import Layout from "../components/layout/FrontLayout";
import ProductArea from "../components/ProductArea";

const Product = () => {
  
  return (
    <>
     <Layout>
        {/* Navigation Bar */}
        <Breadcrumb title={"Contact"} />

        {/* Contact Main */}
        <ProductArea />
     </Layout>
    </>
  );
};

export default Product;
