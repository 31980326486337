import React, { useState } from "react";
import {
  FaArrowRight,
  FaEnvelope,
  FaFacebookF,
  FaMapMarkerAlt,
  FaPhoneAlt
} from "react-icons/fa";
import { Link } from "react-router-dom";

import { toast } from "react-hot-toast";
import { getSettingValueByKey } from "../../../utils/settingsUtils";
import $axios from "../../../axios";

const FooterThree = ({webimage,social,service,section,appUrl,settings}) => {

  const [formData, setFormData] = useState({ email: ''});
  const [subMsg, setsubMsg] = useState('');


  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubSubmit = async (e) => {

    try {
      const response = await $axios.post('/subscriber',formData);
      if (response.status === 200) {
        setFormData('');
        setsubMsg('Subscribed');
        toast.success("Subscribed Successfully!");
      }else{
        // setsubMsg('Already been taken.');
        toast.error("Already been taken.");
      }
    } catch (error) {
      // setsubMsg('Already been taken.');
      toast.error("Already been taken.");
      console.error('Error Subscriber:', error);
    }
    
  }
  
  return (
    <>
      {/* ================== Footer One Start ==================*/}
      <footer className="footer-area footer-area_8 bg-black-2-ico bg-cover mt-0">
        <div className="footer-subscribe">
          <div className="container">
            <div className="footer-subscribe-inner style-3 bg-cover">
              <div className="row">
                <div className="col-lg-6 mb-4 mb-lg-0">
                  <h2 className="mb-3 text-white">Newsletters</h2>
                  <p className="mb-0 text-white">
                  {getSettingValueByKey(settings,'subscribe_head')}
                  </p>
                </div>
                <div className='col-lg-6 align-self-center text-lg-end'>
                  <input type='text' placeholder='Your e-mail address' name="email" onChange={handleChange} />
                  <button className='btn btn-black border-radius-0' type="button" onClick={handleSubSubmit} > {subMsg != '' ? subMsg : 'Submit now'}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="widget widget_about">
                <div className="thumb">
                    <img src={webimage.footer_logo_image_url} alt='img' />
                </div>
                <div className="details">
                    <p>{getSettingValueByKey(settings,'footer_text')}</p>
                  <p className="mt-3">
                  <FaPhoneAlt /> {getSettingValueByKey(settings,'contact_number')}
                  </p>
                  <p className="mt-2">
                  <FaEnvelope /> {getSettingValueByKey(settings,'contact_email')}
                  </p>
                  <ul className='social-media'>
                    {social.map((data,index)=>(
                        <li key={data?.id}>
                          <a href={data.link}>
                            <FaFacebookF />
                            {/* <i className="fas fa-facebook"></i> */}
                          </a>
                        </li>
                    ))}
                    </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="widget widget_nav_menu">
                <h4 className="widget-title">Our Service</h4>
                <ul>
                {service.map((data,index)=>(
                    <li key={data?.id}>
                      <Link to={`/service/${data.id}`}><FaArrowRight /> {data.name}</Link>
                    </li>
                ))}
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="widget widget-recent-post">
                <h4 className="widget-title">Contact us</h4>
                <div className="widget widget_contact">
                  <ul className="details text-white">
                    <li>
                      <FaMapMarkerAlt />
                      {getSettingValueByKey(settings,'contact_address')}
                    </li>
                    <li className="mt-3">
                    <FaPhoneAlt /> {getSettingValueByKey(settings,'contact_number')}
                    </li>
                    <li className="mt-2">
                    <FaEnvelope /> {getSettingValueByKey(settings,'contact_email')}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container">
            <div className="row">
              <div className="col-md-6 align-self-center">
              <p>{getSettingValueByKey(settings,'copy_right_text')}</p>
              </div>
              <div className="col-md-6 text-lg-end">
                {section.map((data,index)=>(
                    <Link to={`/section/${data.id}`}>{data.name}</Link>
                ))}
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* ================== Footer One  end ==================*/}
    </>
  );
};

export default FooterThree;
