import React, { useContext, useEffect, useState } from "react";
import Breadcrumb from "../components/Breadcrumb";
import CaseStudyArea from "../components/CaseStudyArea";
import FooterOne from "../components/FooterOne";
import NavBar from "../components/NavBar";
import { Link, useLocation, useParams } from "react-router-dom";
import $axios from "../axios";
import Layout from "../components/layout/FrontLayout";
import ReactHtmlParser from 'html-react-parser';
import { FaArrowLeft, FaArrowRight, FaTrash } from "react-icons/fa";
import { GeneralSettingsContext } from "../contexts/GeneralSettingsContext";
import { getSettingValueByKey } from "../utils/settingsUtils";

const ShoppingCart = ({webimage}) => {

  const settings = useContext(GeneralSettingsContext);
  const [cartProducts, setCartProducts] = useState([]);
  const [formData, setFormData] = useState({});

  useEffect(() => {
    carts();
  }, []);

  const carts = () => {
    setCartProducts(JSON.parse(localStorage.getItem('cartItems')));
  };
  
  const removeFromCart = (product_id) => {
    let existingCartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
    existingCartItems = existingCartItems.filter(item => item.product_id !== product_id);
    localStorage.setItem('cartItems', JSON.stringify(existingCartItems));
    setCartProducts(existingCartItems);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  
  if (!settings) {
    return null;
  }

  return (
    <>
      <Layout>

        {/* Navigation Bar */}
        <Breadcrumb title={'Shopping Cart'} webimage={webimage} />

        <div className="cart pd-top-120 pd-bottom-120">
            <div className="container">
                <div className="cart-content">
                    <div className="table-responsive">
                        <table className="table common-table">
                            <thead>
                            <tr>
                                <th>Product Details</th>
                                <th>Quantity</th>
                                <th>Price</th>
                                <th>Total</th>
                            </tr>
                            </thead>
                            <tbody>
                                { cartProducts.map((data, index) => (
                                    <tr key={index}>
                                        <td>
                                            <div className="cart-item-old">
                                                <div className="d-flex align-items-center gap-3">
                                                    <div className="cart-item__thumb">
                                                        <Link to={`/product-details/${data.item.slug}`} className="link">
                                                            <img src={data.item.thumb_image_url}  alt={`Product ${index}`} className="cover-img" />
                                                        </Link>
                                                    </div>
                                                    <div className="cart-item__content">
                                                        <h6 className="cart-item__title font-heading fw-700 text-capitalize font-18 mb-4"> <a href="product-details.html" className="link">{data.item.name}</a></h6>
                                                        <span className="cart-item__price font-18 text-heading fw-500">Category: <span className="text-body font-14">{data.item.product_category?.name}</span></span>
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-start gap-4 mt-3 mt-lg-4">
                                                    <div className="d-flex align-items-center justify-content-center gap-2">
                                                        <button type="button" onClick={()=>{removeFromCart(data.product_id)}} className="product-card__wishlist style-two delete-btn text-danger"><FaTrash/></button>
                                                        <span className="text-danger">Remove</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="cart-item__count">
                                                <button data-decrease="data-decrease"> <i className="fas fa-minus"></i></button>
                                                <input readOnly type="number" value={data.qty} min="1" onChange={handleChange}/>
                                                <button data-increase="data-increase"><i className="fas fa-plus"></i></button>
                                            </div>
                                        </td>
                                        <td>
                                            <span className="cart-item__totalPrice text-body font-18 fw-400 mb-0">{getSettingValueByKey(settings,'currency')} {data.item.price}</span>
                                        </td>
                                        <td>
                                            <span className="cart-item__totalPrice text-body font-18 fw-400 mb-0">{getSettingValueByKey(settings,'currency')} {parseFloat(data.item.price * data.qty).toFixed(2)}</span>
                                        </td>
                                    </tr>
                                )) }
                            
                            
                            </tbody>
                        </table>
                    </div>

                    <div className="cart-content__bottom d-flex align-items-center justify-content-between gap-2">
                        <Link to="/products" className="btn  btn--black d-flex align-items-center justify-content-center gap-2 pill btn-lg">
                            <span className="icon line-height-1 font-20"> <FaArrowLeft className="pt-1 mt-0" /></span> 
                            Continue Shopping
                        </Link>

                        <Link to="/cart-payment" className="btn btn-base d-flex align-items-center justify-content-center gap-2 pill">
                            Next
                            <span className="icon line-height-1 font-20"> <FaArrowRight className="pt-1 mt-0"/></span> 
                        </Link>
                    </div>
                </div>            
            </div>
        </div>

      </Layout>
    </>
  );
};

export default ShoppingCart;
