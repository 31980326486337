import { createContext, useContext, useState } from 'react';

const CustomerAuthContent = createContext({
    user: null,
    setUser: () => {}
});

export const CustomerAuthProvider = ({ children }) => {
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')) || null);

    // set user to local storage
    const setUserAndLocalStorage = (user) => {
        if (user) {
            localStorage.setItem('user', JSON.stringify(user));
        } else {
            localStorage.removeItem('user');
        }
        setUser(user);
    };

    return (
        <CustomerAuthContent.Provider value={{ user, setUser: setUserAndLocalStorage }}>
            {children}
        </CustomerAuthContent.Provider>
    );
};

export const useAuth = () => {
    return useContext(CustomerAuthContent);
};
