import React from "react";
import { FaArrowRight, FaCalendarAlt, FaRegUser } from "react-icons/fa";
import { Link } from "react-router-dom";
import { getSettingValueByKey } from "../../../utils/settingsUtils";
import ReactHtmlParser from 'html-react-parser';

const BlogAreaThree = ({settings, blogs}) => {
const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
};

  return (
    <>
      {/* blog-area start */}
      <div className="blog-area blog-area_9 bg-black-ico pd-top-90 pd-bottom-120">
        <div className="container pb-5">
          <div className="section-title">
            <div className="row">
              <div className="col-lg-5">
                <h6 className="sub-title line-after text-white">{getSettingValueByKey(settings,'blog_head')}</h6>
                <h2 className="title text-white">
                {getSettingValueByKey(settings,'blog_title')}
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            {blogs.map((data,index)=>(
            <div className="col-lg-6">
              <div className="single-blog-list bg-black-2-ico">
                <div className="row g-0">
                  <div className="col-sm-5">
                    <div className="thumb">
                        <img src={data.image_url} alt={data.name} />
                    </div>
                  </div>
                  <div className="col-sm-7 align-self-center">
                    <div className="details">
                      <ul className="blog-meta ps-0 text-white">
                        <li>
                          <FaRegUser className="mb-1" /> By Admin
                        </li>
                        <li>
                        <FaCalendarAlt className="mb-1"/> {formatDate(data.updated_at)}
                        </li>
                      </ul>
                      <h5 className="mb-3 text-white">
                        <Link to={`/blog-detail/${data.id}`}>{data.name}</Link>
                      </h5>
                      <p className="mb-3 text-white">
                        {ReactHtmlParser(data.detail.slice(0,100))}
                      </p>
                      <Link className="read-more-text text-white" to={`/blog-detail/${data.id}`}>Read More <FaArrowRight /></Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            ))}
          </div>
        </div>
      </div>
      {/* blog-area start */}
    </>
  );
};

export default BlogAreaThree;
