import React from "react";
import Breadcrumb from "../../components/Breadcrumb";
import Layout from "../../components/layout/FrontLayout";
import ForgetPassword from "../../components/customer/ForgetPassword";

const ForgetPasswordMain = () => {
  
  return (
    <>
     <Layout>
        {/* Navigation Bar */}
        <Breadcrumb title={"Forget Password"} />

        <ForgetPassword />
     </Layout>
    </>     
  );
};

export default ForgetPasswordMain;
