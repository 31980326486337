import React, { useState } from "react";
import {
  FaArrowRight,
  FaEnvelope,
  FaFacebookF,
  FaPhoneAlt,
  FaYoutube
} from "react-icons/fa";
import { Link } from "react-router-dom";
import $axios from "../../../axios";
import { toast } from "react-hot-toast";
import { getSettingValueByKey } from "../../../utils/settingsUtils";

const FooterTwo = ({webimage,social,service,section,appUrl,settings}) => {

  const [formData, setFormData] = useState({ email: ''});
  const [subMsg, setsubMsg] = useState('');


  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubSubmit = async (e) => {

    try {
      const response = await $axios.post('/subscriber',formData);
      if (response.status === 200) {
        setFormData('');
        setsubMsg('Subscribed');
        toast.success("Subscribed Successfully!");
      }else{
        // setsubMsg('Already been taken.');
        toast.error("Already been taken.");
      }
    } catch (error) {
      // setsubMsg('Already been taken.');
      toast.error("Already been taken.");
      console.error('Error Subscriber:', error);
    }
    
  }
  
  return (
    <>
    {/* ================== Footer Four Start ==================*/}
    <footer className="footer-area footer-area_5 bg-black mt-0">
      <div className="container">
        <div className="footer-top-2 bg-base p-5 border-radius-bottom-20">
            <div className="row">
              <div className="col-lg-4">
                <div className="media">
                  <div className="media-left me-3">
                    <img src="assets/img/home-6/10.png" alt="img" />
                  </div>
                  <div className="media-body">
                    <h5 className="text-white">Telephone Info</h5>
                    <p className="text-white mb-0">
                    {getSettingValueByKey(settings,'contact_number')}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="media">
                  <div className="media-left me-3">
                    <img src="assets/img/home-6/7.png" alt="img" />
                  </div>
                  <div className="media-body">
                    <h5 className="text-white">Email Info</h5>
                    <p className="text-white mb-0">{getSettingValueByKey(settings,'contact_email')}</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="media">
                  <div className="media-left me-3">
                    <img src="assets/img/home-6/9.png" alt="img" />
                  </div>
                  <div className="media-body">
                    <h5 className="text-white">Location Info</h5>
                    <p className="text-white mb-0">
                    {getSettingValueByKey(settings,'contact_address')}
                    </p>
                  </div>
                </div>
              </div>
            </div>
        </div>
        <div className="row pd-top-100">
          <div className="col-xl-3 col-lg-3 col-md-6">
            <div className="widget widget_about">
              <div className="thumb">
              <img src={webimage.footer_logo_image_url} alt='img' />
              </div>
              <div className="details">
                <p>
                {getSettingValueByKey(settings,'subscribe_head')}
                </p>
                <div className="subscribe mt-4">
                    <input type='text' className="pe-2 border-radius-0" placeholder='E-mail' name="email" onChange={handleChange} />
                    <button className='btn btn-black border-radius-0' type="button" onClick={handleSubSubmit} > {subMsg != '' ? subMsg : 'Submit'}</button>
                </div>
                <ul className="social-media">
                    {social.map((data,index)=>(
                        <li key={data?.id}>
                          <a href={data.link}>
                            <FaFacebookF />
                            {/* <i className="fas fa-facebook"></i> */}
                          </a>
                        </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="widget widget_nav_menu">
            <h4 className="widget-title">Details in short</h4>
                <p>{getSettingValueByKey(settings,'footer_text')}</p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="widget widget_nav_menu ps-xl-5">
              <h4 className="widget-title">All Services</h4>
              <ul>
                {service.map((data,index)=>(
                    <li key={data?.id}>
                      <Link to={`/service/${data.id}`}><FaArrowRight /> {data.name}</Link>
                    </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="widget widget_nav_menu">
              <h4 className="widget-title">All Section</h4>
              <ul>
              {section.map((data,index)=>(
                    <li key={data?.id}>
                        <Link to={`/section/${data.id}`}>{data.name}</Link>
                    </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom bg-base">
        <div className="container">
          <div className="row">
            <div className="col-md-12 align-self-center text-center">
            <p>{getSettingValueByKey(settings,'copy_right_text')}</p>
            </div>
          </div>
        </div>
      </div>
    </footer>

    {/* ================== Footer Two  end ==================*/}
  </>
  );
};

export default FooterTwo;
