import React, { useContext, useRef, useState } from "react";
import { GeneralSettingsContext } from "../../contexts/GeneralSettingsContext";
import $axios from "../../axios";
import { getSettingValueByKey } from "../../utils/settingsUtils";
import toast, { Toaster } from "react-hot-toast";
import { useAuth } from "../../contexts/CustomerAuthContext";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";


const Login = () => {
  const form = useRef();
  const settings = useContext(GeneralSettingsContext);
  const [formData, setFormData] = useState({});
  const { setUser } = useAuth();
  const { register, handleSubmit, setError, reset, formState: { errors } } = useForm();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  if (!settings) {
    return null;
  }

  const submitLogin = async (formData) => {
    // e.preventDefault();
    try{

      const response = await $axios.post('/customer/login',formData);
      // console.log(response);
      // return false;
      
      if (response.status === 200) {
        toast.success('Login successful! Redirecting you to your dashboard.');
        setTimeout(()=>{
            setUser(response.data);
            window.location.href = '/customer-dashboard';
        },3000);
      }else{
        toast.error(response.data.message);
      }
    }catch (error) {
      const errorMessage = error.response?.status === 422 ? 
      Object.values(error.response.data.error).flat()[0] :
      error.response?.data.error || 'Something went wrong';
      console.error('Error reset Submit:', errorMessage);

      toast.error(errorMessage);
      // toast.error("The provided credentials are incorrect.");
      console.error('Error login:', error);
    }
  };
  return (
    <>
      {/* ================= Contact Main start =================*/}
      <Toaster position='bottom-center' reverseOrder={false} />
      <div className='contact-area pd-top-120 pd-bottom-120'>
        <div className='container'>
          <div className='contact-page-inner bg-gray border border-dark'>
            <div className='section-title mb-4 pb-2'>
              <h2 className='title'>Welcome Back to {getSettingValueByKey(settings,'web_name')}</h2>
              <p className='content mb-0'>Log in to access your account and manage your services seamlessly.</p>
            </div>
            <form ref={form} onSubmit={handleSubmit(submitLogin)}>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='single-input-inner'>
                    <input
                      id='email'
                      name='email'
                      type='email'
                      placeholder='Enter Your Email.'
                      style={errors.email ? { border: "1px solid red" } : {}}
                      onChange={handleChange}
                      {...register("email", {
                        required: "Email field is required.",
                        maxLength: {
                            value: 255,
                            message: "This input exceeds max length 255.",
                        },
                        pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "Invalid email address."
                        }
                      })}
                    />
                    {errors.email && <small className="text-danger">{errors.email?.message}</small>}
                  </div>
                </div>
                <div className='col-md-12'>
                  <div className='single-input-inner'>
                    <input
                      id='password'
                      name='password'
                      type='password'
                      placeholder='Enter Your Password.'
                      style={errors.password ? { border: "1px solid red" } : {}}
                      onChange={handleChange}
                      {...register("password", {
                        required: "Password field is required.",
                        maxLength: {
                            value: 100,
                            message: "This input exceeds max length 100.",
                        },
                        minLength :{
                          value: 6,
                          message: "This input minimum length 6.",
                        }
                        })}
                    />
                    {errors.password && <small className="text-danger">{errors.password?.message}</small>}
                  </div>
                </div>
                <div className='col-12 text-center'>
                  <button
                    className='btn btn-base border-radius-5'
                    type='submit'
                  >
                    Sign In
                  </button>
                  <p className='content mb-0 mt-2'>Forgot Password? <Link to='/forget-password' className="text-primary">Forget Password</Link> </p>
                  <p className='content mb-0 mt-2'>Don't Have An Account? <Link to='/register' className="text-primary">Sign Up</Link> </p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

    </>
  );
};

export default Login;
