import React, { useContext } from "react";
import ReactHtmlParser from 'html-react-parser';
import { GeneralSettingsContext } from "../../../contexts/GeneralSettingsContext";
import { getSettingValueByKey } from "../../../utils/settingsUtils";


const AboutAreaThree = ({webimage}) => {

  const setting = useContext(GeneralSettingsContext);

  if(!setting){
    return null;
  }

  return (
    <>
    {/* about area start */}
    <div className="about-area about-area_9 bg-black-ico pd-top-120 pd-bottom-90">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div
              className="about-thumb-inner pe-xl-5 me-xl-5 wow animated fadeInLeft"
              data-wow-duration="1.5s"
              data-wow-delay="0.3s"
            >
              <img
                className="main-img"
                src={webimage.about_image_url}
                alt="img"
              />
            </div>
          </div>
          <div
            className="col-lg-6 wow animated fadeInRight"
            data-wow-duration="1.5s"
            data-wow-delay="0.3s"
          >
            <div className="section-title mt-5 mt-lg-0">
              <h6 className="sub-title line-after text-white mb-3">
              {getSettingValueByKey(setting,'about_head')}
              </h6>
              <h2 className="title text-white">
              {getSettingValueByKey(setting,'about_title')}
              </h2>
              <p className="content mb-4">
              {ReactHtmlParser(`${getSettingValueByKey(setting,'about_detail')}`)}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* about area end */}
  </>
  );
};

export default AboutAreaThree;
