import React, { useContext, useEffect, useState } from "react";
import { GeneralSettingsContext } from "../contexts/GeneralSettingsContext";
import { fetchGallery } from "../scripts/galleryList";
import { getSettingValueByKey } from "../utils/settingsUtils";
import CaseStudyAreaThree from "./templates/three/CaseStudyAreaThree";
import CaseStudyAreaTwo from "./templates/two/CaseStudyAreaTwo";
import CaseStudyAreaTemOne from "./templates/one/CaseStudyAreaTemOne";

const CaseStudyAreaOne = () => {

  const settings = useContext(GeneralSettingsContext);
  const [gallery, setGalleries] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchGallery();
      setGalleries(data);
     
    };

    fetchData();
  }, []);

  const renderTemplate = () => {
    switch (getSettingValueByKey(settings,'template_status')) {
      case '1':
        return <CaseStudyAreaTemOne gallery={gallery} settings={settings} />;
      case '2':
        return <CaseStudyAreaTwo gallery={gallery} settings={settings} />;
      case '3':
          return <CaseStudyAreaThree gallery={gallery} settings={settings} />;
      default:
        return <CaseStudyAreaTemOne gallery={gallery} settings={settings}/>;
    }
  };

  if (!settings) {
    return null;
  }

  return (
    <>
     {renderTemplate()}
    </>
  );
};

export default CaseStudyAreaOne;
