import React, { useContext, useEffect, useState } from "react";
import {
  FaArrowRight,
  FaCalendarAlt,
  FaEnvelope,
  FaFacebookF,
  FaInstagram,
  FaPhoneAlt,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { GeneralSettingsContext } from "../contexts/GeneralSettingsContext";
import { getSettingValueByKey } from "../utils/settingsUtils";
import { fetchSocial } from "../scripts/socialList";
import { fetchServices } from "../scripts/serviceList";
import { fetchSection } from "../scripts/sectionList";
import $axios from "../axios";
import { toast, Toaster } from "react-hot-toast";
import FooterTemOne from "./templates/one/FooterTemOne";
import FooterTwo from "./templates/two/FooterTwo";
import FooterThree from "./templates/three/FooterThree";

const FooterOne = ({webimage}) => {

  const settings = useContext(GeneralSettingsContext);
  const [social, setSocial] = useState([]);
  const [service, setServices] = useState([]);
  const [section, setSection] = useState([]);
  const appUrl = process.env.REACT_APP_URL;
  const [formData, setFormData] = useState({ email: ''});
  const [subMsg, setsubMsg] = useState('');

  useEffect(() => {
    fetchSocialData();
    fetchServiceData();
    fetchSectionData();
  }, []);

  const fetchSocialData = async () => {
    const data = await fetchSocial();
    setSocial(data);
  };
  
  const fetchServiceData = async () => {
    const data = await fetchServices();
    setServices(data);
  };

  const fetchSectionData = async () => {
    const data = await fetchSection();
    setSection(data);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubSubmit = async (e) => {

    try {
      const response = await $axios.post('/subscriber',formData);
      if (response.status === 200) {
        setFormData('');
        setsubMsg('Subscribed');
        toast.success("Subscribed Successfully!");
      }else{
        // setsubMsg('Already been taken.');
        toast.error("Already been taken.");
      }
    } catch (error) {
      // setsubMsg('Already been taken.');
      toast.error("Already been taken.");
      console.error('Error Subscriber:', error);
    }
    
  }

  const renderTemplate = () => {
    switch (getSettingValueByKey(settings,'template_status')) {
      case '1':
        return <FooterTemOne webimage={webimage} social={social} service={service} section={section} appUrl={appUrl} settings={settings} />;
      case '2':
        return <FooterTwo webimage={webimage} social={social} service={service} section={section} appUrl={appUrl} settings={settings} />;
      case '3':
          return <FooterThree webimage={webimage} social={social} service={service} section={section} appUrl={appUrl} settings={settings} />;
      default:
        return <FooterTemOne webimage={webimage} social={social} service={service} section={section} appUrl={appUrl} settings={settings}/>;
    }
  };
  

  if (!settings) {
    return null;
  }
  return (
    <>
      {/* ================== Footer One Start ==================*/}
      <Toaster position='bottom-center' reverseOrder={false} />
      {renderTemplate()}
      {/* ================== Footer One  end ==================*/}
    </>
  );
};

export default FooterOne;
