import React from "react";
import { FaAngleDoubleRight, FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import Slider from 'react-slick';
import { getSettingValueByKey } from "../../../utils/settingsUtils";

const CaseStudyAreaTwo = ({settings, gallery}) => {

    function SampleNextArrow(props) {
        const { className, onClick } = props;
        return <FaAngleRight className={className} onClick={onClick} />;
      }
      function SamplePrevArrow(props) {
        const { className, onClick } = props;
        return <FaAngleLeft className={className} onClick={onClick} />;
      }
      const setting = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2,
              arrows: false,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              arrows: false,
            },
          },
        ],
      };

    return (
        <>
            {/*================== case-study area start ==================*/}
            <div
            className="gallery-area bg-relative pd-top-120 pd-bottom-90"
            style={{ backgroundImage: 'url("./assets/img/bg/15.png")' }}
            >
            <div className="container ">
                <div className="row">
                <div className="col-lg-5">
                    <div className="section-title style-white">
                    <h6 className="bg-none color-base">{getSettingValueByKey(settings,'gallery_head')}</h6>
                    <h2 className="title">{getSettingValueByKey(settings,'gallery_title')}</h2>
                    </div>
                </div>
                </div>
                <div className="team-slider owl-carousel slider-control-round white slider-control-right-top">
                <Slider {...setting}>  
                {gallery.map((data,index) => (
                    <div className="item" key={index}>
                        <div className="single-project-inner img-fit-container style-2">
                            <img src={data.image_url} alt={data.name} />
                            <div className="details">
                            <span>
                                <FaAngleDoubleRight />
                            </span>
                            <Link className="read-more-text" to={`/gallery/${data.id}`}>{data.name}</Link>
                            <p>{data.short_detail.slice(0,50)}</p>
                            </div>
                        </div>
                    </div>
                ))}
                </Slider>
                </div>
            </div>
            </div>

            {/* ================== case-study area end ==================*/}
        </>
        );
};

export default CaseStudyAreaTwo;
