import React, { useEffect, useState } from "react";
import BlogGroup from "../components/BlogGroup";
import Breadcrumb from "../components/Breadcrumb";
import FooterOne from "../components/FooterOne";
import NavBar from "../components/NavBar";
import Layout from "../components/layout/FrontLayout";
import { useParams } from "react-router-dom";
import $axios from "../axios";

const Blog = () => {

  const {id} = useParams();
  const [detail, setDetails] = useState({});

  useEffect(()=>{
    fetchBlogCat();
  },[id]);

  const fetchBlogCat = async () =>{
      const response = await $axios.get('/blog-category/'+id);
      setDetails(response.data);
  }

  return (
    <>
      <Layout>
        {/* Navigation Bar */}
        <Breadcrumb title={detail.name} />

        {/* Blog Group */}
        <BlogGroup blog={detail} />
      </Layout>
    </>
  );
};

export default Blog;
