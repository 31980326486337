import React, { useContext, useEffect, useState } from "react";
import { GeneralSettingsContext } from "../contexts/GeneralSettingsContext";
import { getSettingValueByKey } from "../utils/settingsUtils";
import { FaCheck } from "react-icons/fa";
import { fetchPlan } from "../scripts/planList";
import { Link } from "react-router-dom";
import ReactHtmlParser from 'html-react-parser';

const PricingAreaOne = () => {

  const settings = useContext(GeneralSettingsContext);
  const [plan, setPlan] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchPlan();
      setPlan(data);
     
    };

    fetchData();
  }, []);

 if (!settings) {
  return null;
  }


  return (
    <>
      {/* Pricing Area One start */}
      <div className='pricing-area bg-gray pd-top-120 pd-bottom-90'>
        <div className='container'>
          <div className='section-title text-center'>
            <h6 className='sub-title'>{getSettingValueByKey(settings,'plan_head')}</h6>
            <h2 className='title'>
            {getSettingValueByKey(settings,'plan_title')}
            </h2>
          </div>
          <div className='row'>
           {plan.map((data,index)=>(
            <div key={index} className='col-lg-4 col-md-6'>
              <div className={`single-pricing-inner style-3 ${index===1?'price-active':''}`}>
                <h2 className='mb-3'>
                  {data.price}
                </h2>
                <h5>{data.name}</h5>
                <div>
                  <ul>
                    <li>{ReactHtmlParser(`${data.detail}`)}</li>
                  </ul>
                </div>
                <Link to='/contact' className='btn btn-black border-radius border-radius-0 w-100'>Started</Link>
              </div>
            </div>

           ))}
          </div>
        </div>
      </div>
      {/* Pricing Area One start */}
    </>
  );
};

export default PricingAreaOne;
